import React, { useEffect } from "react";

import FeaturesBig from "components/FeatureImages";
import FeaturesGrid from "components/FeatureGrid";
import Gallery from "components/Gallery";
import Hero from "components/Hero";
import LogoCloud from "components/LogoCloud";
import Footer from "components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import OuterSection from "pages/Smartform/frontpage";

function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Hero />
      {/* <LogoCloud /> */}
      <FeaturesGrid />
      <FeaturesBig />
      {/* <hr></hr>
      <hr></hr> */}
      <OuterSection/>
      {/* <Footer /> */}
      {/* <Gallery /> */}
    </>
  );
}

export default Home;
