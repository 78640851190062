import React from "react";

import {
  AspectRatio,
  Box,
  Circle,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Grey } from "utils/constants";

function FeatureImages() {
  return (
    // <Box bg={"gray.200"}>
    //   <Container
    //     centerContent
    //     maxW={{ base: "container.sm", xl: "container.xl" }}
    //     py={{ base: "1rem", md: "3.5rem" }}
    //   >
    // <Stack bg={Grey} mx={'5%'}>
    <Stack  mx={'5%'}>
      <HStack>
        <AspectRatio width={"40%"} m={"2%"} p={"2%"}>
          <Image
            // src="assets/images/hero/why_aarith.jpg"
            src="assets/images/hero/must-have-section.jpg"
            alt="naruto"
            objectFit="cover"
          />
        </AspectRatio>
        <VStack p={"8%"}>
          <Heading
            as="h4"
            size="lg"
            // color="white"
            color={"black"}
            pb={{ base: "1rem", md: "1.5rem" }}
            textAlign={{ base: "start", md: "center" }}
          >
            Why Aarith is a must-have
          </Heading>

          <UnorderedList
            color={"black"}
            // color={"white"}
            fontSize={"xl"}
            fontFamily="revert"
          >
            {/* <div
            style={{
              width: "500px",
              height: "500px",
              "line-height": "500px",
              "border-radius": "50%",
              "font-size": "50px",
              color: "#fff",
              "text-align": "center",
              background: `${Orange}`,
            }}
            class="circle"
          >
            Easy to integrate
          </div> */}
            <ListItem>Easy to integrate</ListItem>
            <ListItem>Cost-efficient</ListItem>
            <ListItem>Mobile and Web Interface</ListItem>
            <ListItem>Technical and business support</ListItem>
          </UnorderedList>
        </VStack>
      </HStack>
    </Stack>

    /* </Container>
    </Box> */
  );
}

export default FeatureImages;
