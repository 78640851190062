import React, { useEffect } from "react";
// import { Container, Heading } from "@chakra-ui/react";
// import Accordian from "components/Accordian";

const TermsAndConditions = () => {

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '800px',
      margin: 'auto',
      padding: '20px',
      lineHeight: '1.6',
      fontSize: '16px',
      textAlign: 'justify',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
      borderBottom: '0.5px solid #333',
      paddingBottom: '10px',
    },
    paragraph: {
      marginBottom: '15px',
    },
    link: {
      color: '#007bff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    listContainer: {
      marginLeft: '30px',
    },
    listItem: {
      marginBottom: '8px',
    },
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terms of Use</h1>

      <p style={styles.paragraph}>
        This Terms of Use document (“TOS”) is a legal agreement between you, the end user (“You”/“Your”), and Imnemosyne Technologies Private Limited (“Aarith”), the owner of all intellectual property rights in the cloud-based business inventory platform titled “Aarith” (“Platform”). The TOS describes the terms on which Aarith offers access to the Platform and the Services (defined below) to all end users.
      </p>

      <p style={styles.paragraph}>
        PLEASE READ THE TOS CAREFULLY. YOUR ACCEPTANCE OF THIS TOS AND USE OF THE PLATFORM SHALL SIGNIFY YOUR ACCEPTANCE OF THE TOS, THE <span style={styles.link}>PRIVACY POLICY</span> AVAILABLE AT www.aarith.com/privacy-policy, AND THE OTHER WEBSITE POLICIES AS WELL AS YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
      </p>

      <p style={styles.paragraph}>
        This is a legally binding agreement and is enforceable against You. Aarith may modify the terms of the TOS at any time and shall inform You of the same. You will be deemed to have accepted the revised TOS if You continue to access the Platform after the modifications become effective.
      </p>

      <p style={styles.paragraph}>
        If You do not agree to the terms of this TOS, please do not access or use the Platform.
      </p>

      <p style={styles.paragraph}>
        <strong>The following are the terms and conditions on which Aarith agrees to permit You to access and use the Platform and the Services.</strong>
      </p>

      <h2 style={styles.title}>Registration</h2>

      <p style={styles.paragraph}>
        In order to access and use the Platform, You will be required to register Yourself and maintain an Aarith account (“Account”) which will require You to furnish certain information and details, including Your name, e-mail id, and any other information deemed necessary by Aarith (“Account Information”). You agree to keep this information updated at all times.
      </p>

      <p style={styles.paragraph}>
        You shall be responsible for maintaining the confidentiality and security of the password and for all activities that occur in and through Your Account. Aarith and its affiliates/partners are not liable for any harm caused by, or related to the theft of, Your ID, Your disclosure of Your Account Information, or Your authorization to allow another person to access and use the Service using Your Account. However, You may be liable to Aarith and its affiliates/partners for the losses caused to them due to such unauthorized use.
      </p>

      <div style={styles.listContainer}>
        <p style={styles.paragraph}><strong>Account Dos and Don’ts:</strong></p>
        <ul>
          <li style={styles.listItem}>
            You will create the Account on behalf of an entity or firm or in Your individual capacity only if You are a natural person aged 18 years or above and competent to enter into a valid and binding contract.
          </li>
          <li style={styles.listItem}>
            You will not provide any false personal information to Aarith.
          </li>
          <li style={styles.listItem}>
            You shall ensure that the Account Information is complete, accurate and up-to-date at all times.
          </li>
          <li style={styles.listItem}>
            You shall not use any other user’s Account Information or log into that user’s Account.
          </li>
          <li style={styles.listItem}>
            You will not share Your password or do anything that might jeopardize the security of Your Account.
          </li>
          <li style={styles.listItem}>
            On completing the registration process, You shall be entitled to access the Platform and avail of the Services.
          </li>
          <li style={styles.listItem}>
            Your account, ID, and password may not be transferred or sold to another party.
          </li>
        </ul>
      </div>

      <p style={styles.paragraph}>
        You agree to immediately notify Aarith of any unauthorized use of Your account or any other breach of security known to You.
      </p>
      <p style={styles.paragraph}>In order to ensure that Aarith is able to provide high quality services, respond to customer needs, and comply with laws, You hereby consent to let Aarith’s employees and agents access Your Account and records on a case-to-case basis to investigate complaints or other allegations or suspected abuse. Additionally, You agree to disclose to Aarith, and permit Aarith to use Your login ID, password and such other account details with respect to Your account(s) with e-commerce websites/platforms, for the limited purpose of resolving technical problems.
You also grant Aarith the right to disclose to its affiliates / partners / third parties Your Account Information to the extent necessary for the purpose of rendering the Services.
</p>

      {/* Additional content truncated for brevity */}
      <h2 style={styles.title}>Scope of Services</h2>

      <p style={styles.paragraph}>
      Aarith hereby grants You a specific, non-exclusive, non-transferrable and limited license to access and use the Platform (which is public cloud hosted order and/or warehouse management
        application) via the internet, and avail of the services provided by the Platform with respect to warehouse management (“Services”). Aarith reserves the right to make changes to the functionality or the documentation of the Platform and the provision of Services from time to time.
        </p>
      <p style={styles.paragraph}>
      Aarith retains all rights in the Platform and the Services, and grants You only a right and license to use the Platform / application as stated herein. No other license is intended to be granted to You. Aarith reserves all rights in its name, trademarks, copyrights and any other intellectual property.
        </p>
      <p style={styles.paragraph}>
      You may use the Platform and the Service to store data, print and display data in the Service. No other use of the Platform and the Service by You shall be permitted.
      You may access the Platform and the Services using a single user Account via multiple access points. You may allow Your employees, agents and independent contractors to access the Platform via Your Account on Your behalf. However, You agree not to provide any access to Your Account to any third party vendors.
        </p>
      <p style={styles.paragraph}>
      Aarith shall perform all necessary server management and maintenance services with respect to the Platform at no additional cost to You.
        </p>
      <p style={styles.paragraph}>
      Aarith does not guarantee availability of the Platform at all times. Aarith shall use reasonable efforts to make the Services available to You, at all times through the Platform
        </p>
      <p style={styles.paragraph}>
        However, as the Services are provided over the Internet, data and cellular networks, the quality and availability of the same may be affected by factors outside Aarith’s control. Therefore, Aarith shall not be liable for non-availability of the Services at any time. Aarith may try to restore access to the Platform and the Services on a best reasonable and commercially viable basis.
       
        </p>

      <h2 style={styles.title}>Use of Services</h2>
      <p style={styles.paragraph}>
      The Platform is a standard off-the-shelf application. We do not provide any customization in the platform. You agree to use the Services solely for the purpose for which the Services are provided, namely warehousing, and solely to aid Your business. You shall not sublicense or resell the Platform or the Services for the use or benefit of any other organization, entity, business or enterprise.
        </p>
      <p style={styles.paragraph}>
      You agree not to submit or upload to the Platform any material that is illegal, misleading, defamatory, indecent or obscene, threatening, infringing any third party proprietary rights, invasive of personal privacy or otherwise objectionable (collectively, “Objectionable Matter”).
        </p>
      <p style={styles.paragraph}>
      Aarith reserves the right to adopt and amend rules for the permissible use of the Platform and the Services at any time, and You shall be required to comply with such rules. You shall also be required to comply with all applicable laws regarding privacy, data storage etc., or any other
      policy of Aarith, as updated from time to time. Aarith reserves the right to terminate this Agreement and Your access to the Platform, without notice, if You commit any breach of this clause.
        </p>
      <p style={styles.paragraph}>
      As part of the Services, the Platform allows You to upload data / content to it. All user data uploaded or submitted by You to Your Account, shall be Your sole property. You retain all rights in the data uploaded by You to the Platform and shall remain liable for the legality, reliability, integrity, accuracy and copyright permissions thereto of such data. Aarith will use commercially reasonable security measures to protect the User’s data against unauthorized
      disclosure or use.
        </p>
      <p style={styles.paragraph}>
      However, Aarith does not guarantee data security. If Your data is damaged or lost, Aarith will use commercially reasonable means to such data. You agree that You are entering into this agreement in full knowledge of the same.
        </p>
      <p style={styles.paragraph}>
      You shall not alter, resell or sublicense the Platform or the Services to any third party. You shall not reverse engineer the Platform or its software or other technology, circumvent, disable or otherwise interfere with security-related features or any digital rights management mechanisms of the Platform. You will not use the Platform or the Service to (i) build a competitive product or service, (ii) make or have a product with similar ideas, features, functions or graphics of the Platform, (iii) make derivative works based on the Platform / Services; or (iv) copy any features, functions or graphics of the Platform/Services.
        </p>
      <p style={styles.paragraph}>
      Aarith is an intermediary as defined under the Information Technology Act, 2000. Aarith does not monitor or control any data or content uploaded by You to the Platform. You agree not to use or encourage, or permit others to store, upload, modify, update or share any information that:
• belongs to another person and to which you do not have any right;
• is grossly harmful, misleading, harassing, blasphemous defamatory, indecent, obscene, pornographic, paedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, invasive of personal privacy or otherwise objectionable or any data / content that is contrary to
any applicable local, national, and international laws and regulations;
• infringes any patent, trademark, copyright or other proprietary rights;
• violates any law for the time being in force;results in impersonation of any person or entity, or falsely states or otherwise misrepresents your affiliation with a person or entity;
• is someone’s identification documents or sensitive financial information;contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
• threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offense or prevents investigation of any offense or is insulting any other nation; or
• makes available any data / content in contravention of these TOS or applicable policies, or any data / content that You do not have a right to access, store, use or make available to third parties under any law or contractual or fiduciary relationship.
        </p>
      <p style={styles.paragraph}>
      Aarith reserves the right to suspend or terminate Your access to Your Account if You cause any disruption or harm to the Aarith infrastructure or to any third parties, or violate the provisions of the Information Technology Act, 2000, any applicable privacy laws or any of the
      applicable laws. You hereby consent to let Aarith’s employees and agents access Your Account and records on a case-to-case basis to investigate complaints or other allegations or suspected abuse.
        </p>

     


      <h2 style={styles.title}>Fees</h2>

      <p style={styles.paragraph}>This is a paid version of the Platform. The Platform works on a prepaid or recharge model where You may choose from the paid or recharge options (“Fees”) by going to the billing section of your Aarith account.</p>
      <p style={styles.paragraph}>
      The Fees shall be exclusive of all applicable taxes. You may choose to pay the Fees by any of the payment options made available by Aarith including, credit card, debit card, net banking or cheque. There will be no deduction of TDS in case of use of any of the online methods for payments of Fees. However, if You choose to pay by cheque and deduct TDS on the Fees, You shall be given credit for the amount of TDS deducted. If Aarith changes the Fees payable, Aarith shall give You advance notice of these changes via a message to the email address associated with Your Account.
      </p>
      <p style={styles.paragraph}>
      Aarith will bill You through Your chosen payment method, from the date You opt for the paid Services option until termination. All payments are final and non-refundable. You will not be entitled to any cancellation or cooling off period after opting for the paid Services.
      </p>

      <p style={styles.paragraph}>
      All advance paid is non-refundable and the initial upgrade amount paid will have validity of 30 days.
      </p>

      <p style={styles.paragraph}>
      If a customer is unable to go live and start-processing orders in a specified period, his account will be disabled and upgrade fees will be adjusted.
      </p>
      <p style={styles.paragraph}>
      The Services are provided to You via the internet and data and cellular networks, relevant internet charges and network or data charges, roaming charges, etc., applicable for Your use of the internet and the data shall apply (over and above the Fees) while accessing the Platform and availing the Services. You accept responsibility for all such charges that may arise due to Your use of the Platform and the Services.
      
      </p>
      
      <h2 style={styles.title}>Indemnification</h2>
      <p style={styles.paragraph}>
      You shall indemnify and hold harmless, Aarith, its affiliates, any third party content / networks / infrastructure providers and their respective directors, officers, personnel, contractors and agents, for and against any and all claims, losses, damages, costs and expenses arising out of, or relating to, Your use of the Platform and the Services or Your breach of the TOS
      or any other restrictions or guidelines provided by Aarith. This indemnification obligation will survive at all times, including, Your use of the Platform and the Services.
      </p>

      <h2 style={styles.title}>Disclaimer of Warranties</h2>
      <p style={styles.paragraph}>
      THE PLATFORM AND THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “WITH ALL FAULTS AND RISKS” BASIS, WITHOUT WARRANTIES OF ANY KIND. Aarith DOES NOT WARRANT, EXPRESSLY OR BY IMPLICATION, THE ACCURACY OR RELIABILITY OF THE PLATFORM OR THE SERVICES OR ITS SUSTAINABILITY FOR A PARTICULAR PURPOSE OR THE SAFETY/SECURITY OF THE DATA/CONTENT STORED ON THE PLATFORM BY YOU. Aarith DISCLAIMS ALL WARRANTIES WHETHER EXPRESS OR IMPLIED, INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE PLATFORM OR ANY MATERIAL THEREOF WILL BE UNINTERRUPTED OR ERROR-FREE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Aarith DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM AND THE SERVICES WILL RESULT IN COMPLIANCE, FULFILLMENT OR CONFORMITY WITH THE LAWS, REGULATIONS, REQUIREMENTS OR GUIDELINES OF ANY GOVERNMENT OR GOVERNMENTAL AGENCY.
      </p>
      <p style={styles.paragraph}>
      To the maximum extent permitted by applicable law, Aarith provides no warranty on the use of the Platform and the Services, and shall not be liable for the same under any laws applicable to intellectual property rights, libel, privacy, publicity, obscenity or other laws. Aarith also disclaims all liability with respect to the misuse, loss, modification or unavailability of the Platform and the Services.
      </p>


      <h2 style={styles.title}>Limitation of Liability</h2>
      <p style={styles.paragraph}>
      YOU ASSUME THE ENTIRE RISK OF USING THE PLATFORM AND THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL UN1COMMERCE BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PU N1T1VE OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA OR INFORMATION, OR ANY OTHER PEC UN1ARY LOSS) ARISING OUT OF THE USE OF, OR INABILITY TO USE OR ACCESS THE PLATFORM OR THE SERVICES OR FOR ANY SECURITY BREACH OR ANY VIRUS, BUG, UNAUTHORIZED INTERVENTION, DEFECT, OR TECHNICAL MALFUNCTIONING OF THE PLATFORM, WHETHER OR NOT FORESEEABLE AND WHETHER OR NOT UN1COMMERCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OF, OR ACCESS TO, THE SOFTWARE OR THE SERVICES. FURTHER, Aarith SHALL NOT BE LIABLE TO YOU FOR ANY TEMPORARY DISABLEMENT, PERMANENT DISCONTINUANCE OF THE SERVICES BY Aarith, DATA LOSS OR FOR ANY CONSEQUENCES RESULTING FROM SUCH ACTIONS.
Aarith’S AGGREGATE LIABILITY, IF ANY, (WHETHER UNDER CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY OR OTHERWISE) AND THAT OF ITS AFFILIATES SHALL BE LIMITED TO THE TOTAL AMOUNT OF FEES RECEIVED FROM YOU FOR THE ONE (1) MONTH IMMEDIATELY PRECEDING THE DATE THE CLAIM WAS MADE.
DAMAGES, IN THE NATURE, AND TO THE AMOUNT, PROVIDED IN THIS CLAUSE, IS THE ONLY RECOURSE THAT YOU MAY HAVE AGAINST Aarith FOR BREACH BY Aarith OF ANY OF ITS RIGHTS OR OBLIGATIONS HEREUNDER.
      </p>


      <h2 style={styles.title}>Notice and Takedown Mechanism</h2>
      <p style={styles.paragraph}>
      The content uploaded by You may be available on the e-commerce websites / marketplaces in India or abroad, as chosen by You. In such circumstances, the notice and takedown provisions applicable to the particular e-commerce websites / marketplaces will be applicable to the content uploaded by You. Content which is objectionable or infringing will be accordingly taken down. For all other times, the notice and takedown mechanism provided below will apply.
      </p>
      <p style={styles.paragraph}>
      If You are the owner of copyright in any content shared or uploaded on the Platform without Your consent, or You believe that any user of the Platform is storing, hosting, uploading or transmitting data / content, then You are required to send a written notice to the Grievance Officer at Aarith providing the following information/details:
• Description of the work with adequate information to identify the data / content;
• Details establishing that you are the owner or the exclusive licensee of copyright in the content;
• Details establishing that the copy of the content in question is an infringing copy of the content owned by You and that the allegedly infringing act is not considered as a ‘non-infringing’ act under Section 52 of the Copyright Act and is not any other act that is permitted under the Copyright Act;
• Details of the location where the content in question is stored (i.e., the URL of the page where such content is stored);
• Details of the person, if known, who has uploaded the infringing copy of the content; and
• Undertaking that You shall file an infringement suit in the competent court against such person uploading the infringing copy and provide a copy of the orders so obtained to Aarith within twenty one (21) days from the date of receipt of the notice by Aarith. You may also inform the Grievance Officer / Nodal Officer if any content that is in violation of the TOS. The details of the Grievance Officer and the Nodal Officer are as provided below:
Name of the Grievance Oflicer: Sanket Bante
Address of the Grievance Officer: Plot No17, Vrundawan Colony, Parsodi, Bhandara, Maharashtra 441906

Name of the Nodal Officer: Pranali Salunkhe
Address of the Nodal Officer: Plot No17, Vrundawan Colony, Parsodi, Bhandara, Maharashtra 441906
Email Address: ecomet.mktg@gmail.com
If You knowingly misrepresent that any material or activity is infringing, You may be subject to legal liability. Accordingly, if You are not sure whether material available online infringes your copyright, please contact a lawyer.
      </p>


      
      <h2 style={styles.title}>Suspension and Termination</h2>
      <p style={styles.paragraph}>
      The Services will remain in effect until suspended or terminated under this TOS.
      You may terminate Your registration by sending an email to ecometsmb@gmail.com, if You no longer wish to use the Platform. On termination, You will cease to have access to the Platform or any of the Services.
      </p>
      <p style={styles.paragraph}>
      Aarith reserves the right to suspend or terminate Your Account or restrict or prohibit You access to the Platform immediately (a) if Aarith is unable to verify or authenticate Your registration data, email address or other information provided by You, (b) if Aarith believes that Your actions may cause legal liability for You or for Aarith, or all or some of Aarith’s other users, or (c) if Aarith believes You have provided false or misleading registration data or other information, have not updated Your Account Information, have interfered with other users or the administration of the Services, or have violated this TOS or the Privacy Policy. You shall not be entitled to access the Platform or avail the Services if Your Account has been temporarily or indefinitely suspended or terminated by Aarith for any reason whatsoever.
      </p>

      <p style={styles.paragraph}>
      Aarith may, at any time, reinstate any suspended Account, without giving any reason for that. If You have been indefinitely suspended You shall not register or attempt to register with Aarith or its affiliates / partners or use the Services in any manner whatsoever until such time that You are reinstated by Aarith. In addition to the above, If you are not able to go live and start processing orders within 30 days of upgradation date, Aarith shall be entitled to terminate Your Account irrespective of the balance amount available in Your Account. In such cases, Aarith shall not be liable to refund any Fees to You. In addition to the above, if You do not process any order through the Platform for a continuous period of one (1) month, Aarith shall be entitled to terminate Your Account irrespective of the balance amount available in Your Account. In such cases, Aarith shall not be liable to refund any Fees to You. Upon termination of this TOS, Your right to access the Platform and use the Services shall immediately cease. Thereafter, You shall have no right, and Aarith shall have no obligation thereafter, to execute any of the uncompleted tasks
      </p>
      <p style={styles.paragraph}>
      Aarith does not have a refund policy and therefore, no refund of the Fees shall be provided under any circumstance. Once the Services are terminated or suspended, any data that You may have stored on the Platform, may not be retrieved later. Aarith shall be under no obligation to return the information or data to you.
      </p>
      <h2 style={styles.title}>Governing Law</h2>

      <p style={styles.paragraph}>
        This TOS is governed and constructed in accordance with the laws of India. The courts in Delhi alone shall have exclusive jurisdiction to hear disputes arising out of the TOS without any reference to the conflict of law provisions. The Platform is made available to You by Aarith from its offices in India. You agree that: (i) the Services shall be deemed solely based in India; and (ii) the use of the Platform and the Services do not give rise to personal jurisdiction over Aarith, either specific or general, in jurisdictions other than India. You agree that the laws of India, excluding India’s choice of law rules, will apply to these TOS and to the provision of Services by Aarith and Your use of the same. Aarith makes no representation that the Platform and the Services are appropriate or available for use at other locations outside India.
      </p>

      <p style={styles.paragraph}>
        Access to the Platform from jurisdictions where the Services are illegal is prohibited. Aarith reserves the right to block access to the Platform by certain international users. If You access the Platform from a location outside India, You are responsible for compliance with all applicable local laws.
      </p>

      <p style={styles.paragraph}>
        Aarith shall be under no liability whatsoever in case of occurrence of a Force Majeure event, including in case of non-availability of any portion of the Platform and/or Services occasioned by act of God, war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, failure of any public utility, man-made disaster, infrastructure failure, technology outages, failure of technology integration of partners or any other cause whatsoever, beyond the control of Aarith. Further, in case of a force majeure event, Aarith shall not be liable for any breach of security or loss of data uploaded by You to the Platform.
      </p>

      <h2 style={styles.title}>Severability</h2>
      <p style={styles.paragraph}>
      lf any of the provisions of this TOS are deemed invalid, void, or for any reason unenforceable, that part of the TOS will be deemed severable and will not affect the validity and enforceability of any remaining provisions of the TOS.
      </p>

      <h2 style={styles.title}>Waiver</h2>
      <p style={styles.paragraph}>
      Any failure by Aarith to enforce the TOS, for whatever reason, shall not necessarily be construed as a waiver of any right to do so at any time.
      </p>

      <h2 style={styles.title}>Entire Agreement</h2>
      <p style={styles.paragraph}>
      The TOS as amended from time to time, along with the Privacy Policy and other related policies made available from time to time, constitutes the entire agreement and supersedes all prior understandings between the parties relating to the subject matter herein.
        If You have questions or concerns about the TOS, please contact Aarith at <span style={styles.link}>ecometsmb@gmail.com</span>. Aarith is a team of young minds, bringing fresh ideas and processes to the mundane business operations.
      </p>
    </div>
  );
};

export default TermsAndConditions;
