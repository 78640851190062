import BlogBox from "components/BlogBox";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BlogContent } from "utils/constants";

const ViewBlog = () => {
  const location = useLocation();

  // const [selectedBlogContent, setSelectedBlogContent] = useState({});
  const [selectedBlogId,setSelectedBlogId] = useState('')

  useEffect(() => {
    const queryParam = new URLSearchParams(location);
    const idVal = queryParam.get("pathname").substr(-1);
    setSelectedBlogId(idVal)
    // const blogObj = BlogContent.find((i) => idVal === i.id);
    // setSelectedBlogContent(blogObj);
  }, []);

  return (
    <BlogBox
    id={selectedBlogId}
      // header={selectedBlogContent?.header}
      // description={selectedBlogContent?.description}
      // images={selectedBlogContent?.images}
      // subContent={selectedBlogContent?.subContent}
    />
  );

   
  
};

export default ViewBlog;
