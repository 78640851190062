import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import PricingBox from "components/PricingBox";
import { useLocation } from "react-router-dom";
import { Grey, Orange, Purple, modulesContent } from "utils/constants";

import { CheckCircle } from "phosphor-react";
import {
  ArrowCircleRight,
  BusinessCenterSharp,
  RunningWithErrors,
  SupportAgentSharp,
} from "@mui/icons-material";

// const businessPrices = [
//   {
//     name: "Basic",
//     price: "25,000",
//     subHeader: "Startups & Independent Retailers",
//     features: ["Product Performance", "Whatsapp Integration", "Catalouguing"],
//     // features: [
//     //   "Startups & Independent",
//     //   "Single channel presence",
//     //   "< 1 Cr / month",
//     // ],
//     // info: infoMessage,
//   },
//   {
//     name: "Standard",

//     price: "50,000",
//     subHeader: "Small Businesses",
//     features: [
//       "Product Performance",
//       "Campaign Analysis",
//       "Whatsapp Integration",
//       "Catalouguing",
//     ],
//     popular: true,
//     // features: [
//     //   "Small Businesses",
//     //   "2-3 Business Verticals",
//     //   "upto 10cr / monthly",
//     // ],
//     // info: infoMessage,
//   },
//   {
//     name: "Premium",
//     price: "75,000",
//     features: [
//       "Product Performace",
//       "Campaign Analysis",
//       "Whatsapp Integration",
//       "Competitive Insights",
//       "Catalouguing",
//       "Social Media Tracking",
//     ],
//     subHeader: "Enterprise",
//     // features: [
//     //   "Enterprise",
//     //   "Multi-brand Omni-channel presence",
//     //   "10 cr+ Monthly",
//     // ],
//     // info: infoMessage,
//   },
// ];

const Forecasting = () => {
  const location = useLocation();

  // const [productIndex, setProductIndex] = useState(0);
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    // const queryParam = new URLSearchParams(location);
    // // setProductIndex(queryParam.get("pathname").substr(-1));
    // const idVal = queryParam.get("pathname").substr(-1);
    // // console.log(idVal);
    // // console.log(id);
    const productObj = modulesContent[3];
    // console.log(productObj);
    setProductDetails(productObj);
  }, []);

  const getIconView = (boxHeader) => {
    if ("Consumer" === boxHeader) {
      return (
        <SupportAgentSharp
          style={{
            width: "20%",
            height: "20%",
            color: `${Purple}`,
          }}
          fontSize="large"
        />
      );
    }
    if ("Business" === boxHeader) {
      return (
        <BusinessCenterSharp
          style={{
            width: "20%",
            height: "20%",
            color: `${Purple}`,
          }}
          fontSize="large"
        />
      );
    }
    return (
      <RunningWithErrors
        style={{
          width: "20%",
          height: "20%",
          color: `${Purple}`,
        }}
        fontSize="large"
      />
    );
  };

  return (
    <>
    
    <div style={{backgroundColor:`${Purple}`}}>
      <Stack
        spacing={5}
        marginY={5}
        paddingX={[5, 0]}
      >
        <VStack alignItems="center" mt={'8px'} w="full">
          <Heading py={'10px'} color={'white'}> {`अनुमान`} - {productDetails?.header}</Heading>

          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              paddingBottom: "10px",
            }}
          >
            <Text
              // textAlign={"center"}
              // mr={"30%"}
              // ml={"30%"}
              // color={"white"}
              fontWeight={"semibold"}
              fontSize={"lg"}
              color={"white"}
            >
              {productDetails?.subHeader}
            </Text>
          </div>
        </VStack>
      </Stack>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {productDetails?.boxValues?.map((item, index) => (
          <Stack
            spacing={2}
            border="3px solid"
            m={"2%"}
            width={"20%"}
            borderColor={Orange}
            borderRadius="0.7rem"
            p={4}
            h={"350px"}
            backgroundColor="white"
            position="relative"
          >
            {getIconView(item?.name)}

            <Text fontSize={"xl"}>{item?.name}</Text>
            <Divider borderColor="blackAlpha.500" />
            {/* {console.log(productDetails)} */}
            {item?.features?.map((item, index) => (
              <List flex="1">
                <ListItem fontSize={"lg"} key={Math.random()}>
                  <ListIcon as={CheckCircle} color="gray.400" />
                  {item}
                </ListItem>
              </List>
            ))}
          </Stack>
        ))}
      </div>

      
    
    <Container
    centerContent
    maxW={{ base: "container.sm", xl: "container.xl" }}
    py={{ base: "1.5rem", md: "2.5rem" }}
  >
    <Heading
      as="h2"
      size="h2"
      // color="white"
      color={"black"}
      pb={{ base: "1rem", md: "1rem" }}
      textAlign={{ base: "start", md: "center" }}
    >
      {productDetails?.subFooterHeader}
    </Heading>
    <UnorderedList
      color={"black"}
      // color={"white"}
      fontSize={"xl"}
      fontFamily="revert"
    >
      {/* <div
        style={{
          width: "500px",
          height: "500px",
          "line-height": "500px",
          "border-radius": "50%",
          "font-size": "50px",
          color: "#fff",
          "text-align": "center",
          background: `${Orange}`,
        }}
        class="circle"
      >
        Easy to integrate
      </div> */}
      {productDetails?.subFooterListItems?.map((item, index) => (
        <ListItem>{item}</ListItem>
      ))}
      {/* <ListItem>Easy to integrate</ListItem>
      <ListItem>Cost-efficient</ListItem>
      <ListItem>Mobile and Web Interface</ListItem> */}
    </UnorderedList>
  </Container>
    </>
  );
};

export default Forecasting;
