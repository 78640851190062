import React, { useEffect } from "react";

// import FeaturesBig from "components/FeatureImages";
// import FeaturesGrid from "components/FeatureGrid";
// import Gallery from "components/Gallery";
// import Hero from "components/Hero";
// import LogoCloud from "components/LogoCloud";
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  HStack,
  Heading,
  Image,
  Input,
  Link,
  List,
  ListItem,
  Select,
  Stack,
  Text,
  Textarea,
  useToken,
} from "@chakra-ui/react";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  MapPin,
  Phone,
} from "phosphor-react";

import { useNavigate } from "react-router-dom";

import LogoBlack from "assets/images/brand-logo/Aarith-Logo-Colour.png";
import Footer from "components/Footer";
import { Grey, Orange, Purple } from "utils/constants";

const mainModules = [
  {
    header: "Insights Module",
    description:
      "Leverage real-time analytics and comprehensive reporting tools to gain valuable perspectives on your business, customer and competitor. Uncover trends, identify opportunities, and make informed decisions that drive your business forward.",
  },
  {
    header: "Management Module",
    description:
      "Streamline campaigns, optimize channel presence, and efficiently do catalog management with our intuitive platform. This module provides a centralized platform for overseeing and fine-tuning your marketing efforts.",
  },
  {
    header: "Engagement Module",
    description:
      "Elevate customer engagement and communication; be it on social media, WhatsApp or through emails. Engagement Module truly helps create a more engaging and omnichannel experience for your business.",
  },
  {
    header: "Forecasting Module",
    description:
      "Navigate the future confidently with our Forecasting Module. Leverage advanced algorithms and predictive analytics to anticipate optimal product pricing, discounts to run and inventory maintenance giving your business a strategic advantage.",
  },
];

function Features() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onModuleSelect = (index) => {
    let routeStr;
    switch (index) {
      case "0": {
        routeStr = "/insights";
        break;
      }
      case "1": {
        routeStr = "/management";
        break;
      }
      case "2": {
        routeStr = "/engagement";
        break;
      }
      case "3": {
        routeStr = "/forecasting";
        break;
      }
      default:
        routeStr = "/insights";
    }
    navigate(routeStr);
  };

  return (
    <>
    <div style={{backgroundColor:`${Purple}`}}>
      <Heading
      pt={'15px'}
        textAlign={"center"}
        // color={Purple}
        color={'White'}
      >
        Explore limitless possibilities of data analytics
      </Heading>
      <Text mt={1} 
      // backgroundColor={Grey}
      color={'White'}
       textAlign={'center'} mx={'10%'} marginTop={'1%'} pb={'15px'} fontWeight={"semibold"}>
      Experience the synergy of these four modules, each serving as a core
          foundational pillar in your journey towards business excellence.
          Whether you are an established retail brand, a startup or a marketing
          agency; Aarith's modules are designed to meet your unique needs.
      </Text>
      </div>
      <Container 
    maxW="100%"
    py='3rem'
    >
      {/* <Heading
        textAlign={"center"}
        // color={Purple}
      >
        Explore limitless possibilities of data analytics
      </Heading>
      <Text mt={1} 
      // backgroundColor={Grey}
       textAlign={'center'} mx={'5%'} marginTop={'1%'} fontWeight={"medium"}>
      Experience the synergy of these four modules, each serving as a core
          foundational pillar in your journey towards business excellence.
          Whether you are an established retail brand, a startup or a marketing
          agency; Aarith's modules are designed to meet your unique needs.
      </Text> */}
      <br></br>
      <Stack
        direction={{ base: "column", xl: "row" }}
        alignItems="start"
        mx={'7%'}
        // spacing="3.75rem"
      >
        <Box boxSize={"xl"}>
          <Image
            width={"70%"}
            ml={"10%"}
            borderRadius={"2%"}
            src="assets/images/modules/insights.jpg"
            alt="Dan Abramov"
          />
        </Box>

        <Stack
          direction="column"
          spacing="1.5rem"
          w="100%"
          height={"100%"}
          maxW={{ base: "auto", xl: "517px" }}
        >
          <Container centerContent>
            <Box padding="5" mr={"50%"}>
              <Heading
                _hover={{
                  cursor: "pointer",
                  color: `${Orange}`,
                }}
                onClick={() => {
                  onModuleSelect("0");
                }}
                color={Purple}
                as={"h1"}
                size="2xl"
              >
                {`धीति`} <br></br>
                {`Insights`}
                
              </Heading>
            </Box>
          </Container>
          <Container  centerContent height={"100%"}>
            <Box padding="4" color="black" height={"100%"}>
              <Text textAlign={'justify'} fontSize={"xl"} size="md">
                Leverage real-time analytics and comprehensive reporting tools
                to gain valuable perspectives on your business, customer and
                competitor. Uncover trends, identify opportunities, and make
                informed decisions that drive your business forward.
              </Text>
            </Box>
          </Container>
        </Stack>
      </Stack>

      <Stack
        direction={{ base: "column", xl: "row" }}
        alignItems="start"
        // spacing="3.75rem"
        mx={'7%'}
      >
        <Stack
          direction="column"
          spacing="1.5rem"
          w="100%"
          height={"100%"}
          maxW={{ base: "auto", xl: "517px" }}
        >
          <Container centerContent>
            <Box padding="5" mr={"25%"}>
              <Heading
                _hover={{
                  cursor: "pointer",
                  color: `${Orange}`,
                }}
                onClick={() => {
                  onModuleSelect("1");
                }}
                color={Purple}
                as={"h1"}
                size="2xl"
              >
                {`क्रिया`} <br></br>
                {`Management`}
              </Heading>
            </Box>
          </Container>
          <Container centerContent height={"100%"}>
            <Box padding="4" color="black" height={"100%"}>
              <Text textAlign={'justify'} fontSize={"xl"} size="md">
                Streamline campaigns, optimize channel presence, and efficiently
                do catalog management with our intuitive platform. This module
                provides a centralized platform for overseeing and fine-tuning
                your marketing efforts.
              </Text>
            </Box>
          </Container>
        </Stack>
        <Box boxSize={"xl"}>
          <Image
            width={"70%"}
            ml={"10%"}
            borderRadius={"2%"}
            src="assets/images/modules/management.jpg"
            alt="Dan Abramov"
          />
        </Box>
      </Stack>
      <Stack
        direction={{ base: "column", xl: "row" }}
        alignItems="start"
        // spacing="3.75rem"
        mx={'7%'}
      >
        <Box boxSize={"xl"}>
          <Image
            width={"70%"}
            ml={"10%"}
            borderRadius={"2%"}
            src="assets/images/modules/engagement.jpg"
            alt="Dan Abramov"
          />
        </Box>

        <Stack
          direction="column"
          spacing="1.5rem"
          w="100%"
          height={"100%"}
          maxW={{ base: "auto", xl: "517px" }}
        >
          <Container centerContent>
            <Box padding="5" mr={"30%"}>
              <Heading
                _hover={{
                  cursor: "pointer",
                  color: `${Orange}`,
                }}
                onClick={() => {
                  onModuleSelect("2");
                }}
                color={Purple}
                as={"h1"}
                size="2xl"
              >
                {`संचार`} <br></br>
                {`Engagement`}
              </Heading>
            </Box>
          </Container>
          <Container centerContent height={"100%"}>
            <Box padding="4" color="black" height={"100%"}>
              <Text  textAlign={'justify'} fontSize={"xl"} size="md">
                Elevate customer engagement and communication; be it on social
                media, WhatsApp or through emails. Engagement Module truly helps
                create a more engaging and omnichannel experience for your
                business.
              </Text>
            </Box>
          </Container>
        </Stack>
      </Stack>

      <Stack
        direction={{ base: "column", xl: "row" }}
        alignItems="start"
        // spacing="3.75rem"
        mx={'7%'}
      >
        <Stack
          direction="column"
          spacing="1.5rem"
          w="100%"
          height={"100%"}
          maxW={{ base: "auto", xl: "517px" }}
        >
          <Container centerContent>
            <Box padding="5" mr={"30%"}>
              <Heading
                _hover={{
                  cursor: "pointer",
                  color: `${Orange}`,
                }}
                onClick={() => {
                  onModuleSelect("3");
                }}
                color={Purple}
                as={"h1"}
                size="2xl"
              >
                {`अनुमान`} <br></br>
                {`Forecasting`}
              </Heading>
            </Box>
          </Container>
          <Container centerContent height={"100%"}>
            <Box padding="4" color="black" height={"100%"}>
              <Text textAlign={'justify'} fontSize={"xl"} size="md">
                Navigate the future confidently with our Forecasting Module.
                Leverage advanced algorithms and predictive analytics to
                anticipate optimal product pricing, discounts to run and
                inventory maintenance giving your business a strategic
                advantage.
              </Text>
            </Box>
          </Container>
        </Stack>
        <Box boxSize={"xl"}>
          <Image
            width={"70%"}
            ml={"10%"}
            borderRadius={"2%"}
            src="assets/images/modules/forecasting.jpg"
            alt="Dan Abramov"
          />
        </Box>
      </Stack>
      <Container marginBottom={"2%"} centerContent>
        <Button
          color={"white"}
          backgroundColor={Purple}
        >
          Book a Demo
        </Button>
      </Container>
      <HStack mt={"1%"} mr={"5%"} ml={"5%"}>
        <Box fontWeight={"medium"}>
         
          <Heading textAlign={'center'} color={Purple} as={"h5"} size="lg">
            Discover the Aarith advantage
          </Heading>{" "}
          <Text pt={1} textAlign={'center'}>

          Join us as we redefine business analytics and empower you towards
          sustainable growth and success. – where innovation, precision and
          performance converge in the world of business analytics.
          </Text>
        </Box>
      </HStack>
    </Container>
    </>
    // <Container 
    // maxW="100%"
    // py='3rem'
    // >
    //   {/* <Heading
    //     textAlign={"center"}
    //     // color={Purple}
    //   >
    //     Explore limitless possibilities of data analytics
    //   </Heading>
    //   <Text mt={1} 
    //   // backgroundColor={Grey}
    //    textAlign={'center'} mx={'5%'} marginTop={'1%'} fontWeight={"medium"}>
    //   Experience the synergy of these four modules, each serving as a core
    //       foundational pillar in your journey towards business excellence.
    //       Whether you are an established retail brand, a startup or a marketing
    //       agency; Aarith's modules are designed to meet your unique needs.
    //   </Text> */}
    //   <br></br>
    //   <Stack
    //     direction={{ base: "column", xl: "row" }}
    //     alignItems="start"
    //     mx={'7%'}
    //     // spacing="3.75rem"
    //   >
    //     <Box boxSize={"xl"}>
    //       <Image
    //         width={"70%"}
    //         ml={"10%"}
    //         borderRadius={"2%"}
    //         src="assets/images/modules/insights.jpg"
    //         alt="Dan Abramov"
    //       />
    //     </Box>

    //     <Stack
    //       direction="column"
    //       spacing="1.5rem"
    //       w="100%"
    //       height={"100%"}
    //       maxW={{ base: "auto", xl: "517px" }}
    //     >
    //       <Container centerContent>
    //         <Box padding="5" mr={"50%"}>
    //           <Heading
    //             _hover={{
    //               cursor: "pointer",
    //               color: `${Orange}`,
    //             }}
    //             onClick={() => {
    //               onModuleSelect("0");
    //             }}
    //             color={Purple}
    //             as={"h1"}
    //             size="2xl"
    //           >
    //             {`धीति`} <br></br>
    //             {`Insights`}
                
    //           </Heading>
    //         </Box>
    //       </Container>
    //       <Container  centerContent height={"100%"}>
    //         <Box padding="4" color="black" height={"100%"}>
    //           <Text textAlign={'justify'} fontSize={"xl"} size="md">
    //             Leverage real-time analytics and comprehensive reporting tools
    //             to gain valuable perspectives on your business, customer and
    //             competitor. Uncover trends, identify opportunities, and make
    //             informed decisions that drive your business forward.
    //           </Text>
    //         </Box>
    //       </Container>
    //     </Stack>
    //   </Stack>

    //   <Stack
    //     direction={{ base: "column", xl: "row" }}
    //     alignItems="start"
    //     // spacing="3.75rem"
    //     mx={'7%'}
    //   >
    //     <Stack
    //       direction="column"
    //       spacing="1.5rem"
    //       w="100%"
    //       height={"100%"}
    //       maxW={{ base: "auto", xl: "517px" }}
    //     >
    //       <Container centerContent>
    //         <Box padding="5" mr={"25%"}>
    //           <Heading
    //             _hover={{
    //               cursor: "pointer",
    //               color: `${Orange}`,
    //             }}
    //             onClick={() => {
    //               onModuleSelect("1");
    //             }}
    //             color={Purple}
    //             as={"h1"}
    //             size="2xl"
    //           >
    //             {`क्रिया`} <br></br>
    //             {`Management`}
    //           </Heading>
    //         </Box>
    //       </Container>
    //       <Container centerContent height={"100%"}>
    //         <Box padding="4" color="black" height={"100%"}>
    //           <Text textAlign={'justify'} fontSize={"xl"} size="md">
    //             Streamline campaigns, optimize channel presence, and efficiently
    //             do catalog management with our intuitive platform. This module
    //             provides a centralized platform for overseeing and fine-tuning
    //             your marketing efforts.
    //           </Text>
    //         </Box>
    //       </Container>
    //     </Stack>
    //     <Box boxSize={"xl"}>
    //       <Image
    //         width={"70%"}
    //         ml={"10%"}
    //         borderRadius={"2%"}
    //         src="assets/images/modules/management.jpg"
    //         alt="Dan Abramov"
    //       />
    //     </Box>
    //   </Stack>
    //   <Stack
    //     direction={{ base: "column", xl: "row" }}
    //     alignItems="start"
    //     // spacing="3.75rem"
    //     mx={'7%'}
    //   >
    //     <Box boxSize={"xl"}>
    //       <Image
    //         width={"70%"}
    //         ml={"10%"}
    //         borderRadius={"2%"}
    //         src="assets/images/modules/engagement.jpg"
    //         alt="Dan Abramov"
    //       />
    //     </Box>

    //     <Stack
    //       direction="column"
    //       spacing="1.5rem"
    //       w="100%"
    //       height={"100%"}
    //       maxW={{ base: "auto", xl: "517px" }}
    //     >
    //       <Container centerContent>
    //         <Box padding="5" mr={"30%"}>
    //           <Heading
    //             _hover={{
    //               cursor: "pointer",
    //               color: `${Orange}`,
    //             }}
    //             onClick={() => {
    //               onModuleSelect("2");
    //             }}
    //             color={Purple}
    //             as={"h1"}
    //             size="2xl"
    //           >
    //             {`संचार`} <br></br>
    //             {`Engagement`}
    //           </Heading>
    //         </Box>
    //       </Container>
    //       <Container centerContent height={"100%"}>
    //         <Box padding="4" color="black" height={"100%"}>
    //           <Text  textAlign={'justify'} fontSize={"xl"} size="md">
    //             Elevate customer engagement and communication; be it on social
    //             media, WhatsApp or through emails. Engagement Module truly helps
    //             create a more engaging and omnichannel experience for your
    //             business.
    //           </Text>
    //         </Box>
    //       </Container>
    //     </Stack>
    //   </Stack>

    //   <Stack
    //     direction={{ base: "column", xl: "row" }}
    //     alignItems="start"
    //     // spacing="3.75rem"
    //     mx={'7%'}
    //   >
    //     <Stack
    //       direction="column"
    //       spacing="1.5rem"
    //       w="100%"
    //       height={"100%"}
    //       maxW={{ base: "auto", xl: "517px" }}
    //     >
    //       <Container centerContent>
    //         <Box padding="5" mr={"30%"}>
    //           <Heading
    //             _hover={{
    //               cursor: "pointer",
    //               color: `${Orange}`,
    //             }}
    //             onClick={() => {
    //               onModuleSelect("3");
    //             }}
    //             color={Purple}
    //             as={"h1"}
    //             size="2xl"
    //           >
    //             {`अनुमान`} <br></br>
    //             {`Forecasting`}
    //           </Heading>
    //         </Box>
    //       </Container>
    //       <Container centerContent height={"100%"}>
    //         <Box padding="4" color="black" height={"100%"}>
    //           <Text textAlign={'justify'} fontSize={"xl"} size="md">
    //             Navigate the future confidently with our Forecasting Module.
    //             Leverage advanced algorithms and predictive analytics to
    //             anticipate optimal product pricing, discounts to run and
    //             inventory maintenance giving your business a strategic
    //             advantage.
    //           </Text>
    //         </Box>
    //       </Container>
    //     </Stack>
    //     <Box boxSize={"xl"}>
    //       <Image
    //         width={"70%"}
    //         ml={"10%"}
    //         borderRadius={"2%"}
    //         src="assets/images/modules/forecasting.jpg"
    //         alt="Dan Abramov"
    //       />
    //     </Box>
    //   </Stack>
    //   <Container marginBottom={"2%"} centerContent>
    //     <Button
    //       color={"white"}
    //       backgroundColor={Purple}
    //     >
    //       Book a Demo
    //     </Button>
    //   </Container>
    //   <HStack mt={"1%"} mr={"5%"} ml={"5%"}>
    //     <Box fontWeight={"medium"}>
         
    //       <Heading textAlign={'center'} color={Purple} as={"h5"} size="lg">
    //         Discover the Aarith advantage
    //       </Heading>{" "}
    //       <Text pt={1} textAlign={'center'}>

    //       Join us as we redefine business analytics and empower you towards
    //       sustainable growth and success. – where innovation, precision and
    //       performance converge in the world of business analytics.
    //       </Text>
    //     </Box>
    //   </HStack>
    // </Container>
  );
}

export default Features;
