import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  AspectRatio,
  Box,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BlogContent, Teal } from "utils/constants";

// import { CloseButton } from "react-bootstrap";

const articleStyle = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Arial, sans-serif',
  lineHeight: '1.6',
  textAlign: 'left',
};

const titleStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '20px',
  color: '#333',
  borderBottom: '2px solid #ddd',
  paddingBottom: '10px',
};

const sectionTitleStyle = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '20px',
  marginBottom: '10px',
  color: '#555',
};

const contentStyle = {
  marginBottom: '20px',
};

const emphasisStyle = {
  fontWeight: 'bold',
  color: '#007bff',
};

const imageStyle = {
  // maxWidth: '100%',
  width:'100%',
  height: 'auto',
  marginBottom: '20px',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

// const BlogBox = ({ header, description, subContent, images,id }) => {
const BlogBox = ({ id }) => {

  const [blogContent,setBlogContent] = useState({})

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(()=> {
    const blogObj = BlogContent.find((i) => id === i.id);
    setBlogContent(blogObj);
  },[id])
 
  const contentToggleHandler = (toggleDirection) => {

    let blogObj = {};
    const currentBlogId = +blogContent?.id

    if('prev' === toggleDirection) {
      if(!currentBlogId || 0 === currentBlogId) {
        return 
      }

      blogObj = BlogContent.find((i) => i.id === `${currentBlogId - 1}`)
      setBlogContent(blogObj)
      window.scroll(0, 0);
      return 
    }

    if(5 !== currentBlogId) {
      blogObj = BlogContent.find((i) => i.id === `${currentBlogId + 1}`)
      window.scroll(0, 0);
      setBlogContent(blogObj)
    }

  }


  return (
    <div style={articleStyle}>
      {/* {console.log(`dfsdf ${blogContent?.images ? blogContent?.images[0]?.url:'https://via.placeholder.com/800x400'}`) } */}
      <img
        // src="https://via.placeholder.com/800x400"
        src={blogContent?.images ? blogContent?.images[0]?.url:'https://via.placeholder.com/800x400'}
        alt="Blog View"
        style={imageStyle}
      />
      <h1 style={titleStyle}>{blogContent?.header}</h1>

      <div style={contentStyle}>
        <p>{blogContent?.description}</p>
      </div>

     
       {blogContent?.subContent?.map((content,i) => {
        return ( <><h2 style={sectionTitleStyle}>{content.header}</h2>

          <div style={contentStyle}>
            <p>{content.content}</p>
          </div></>)
       })}

<div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button
          onClick={() => contentToggleHandler('prev')}
          // disabled={currentBlogIndex === 0}
          style={{ marginRight: '10px',color:Teal,cursor:`${blogContent?.id === '0' ? 'not-allowed':'pointer'}` }}
        >
          Previous
        </button>
         <button
         style={{color:Teal,cursor:`${blogContent?.id === '5' ? 'not-allowed':'pointer'}`}}
          onClick={() => contentToggleHandler('next')}
          // disabled={currentBlogIndex === blogEntries.length - 1}
        >
          Next
        </button>
      </div>

    </div>
  );
};

export default BlogBox;
