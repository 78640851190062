import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { Grey, Orange } from "utils/constants";

const Accordian = ({ data }) => {
  return (
    <Accordion size={"xl"} allowToggle>
      {data.map((item, index) => (
        <AccordionItem mr={"20%"} ml={"20%"}>
          <h2>
            <AccordionButton
              _expanded={{ bg: `${Orange}`, color: "white", border: "none" }}
            >
              <Box as="span" flex="1" textAlign="left">
                {item.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel backgroundColor={Grey} fontWeight={'semibold'} pb={4}>{item.description}</AccordionPanel>
        </AccordionItem>
      ))}

      {/* <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Section 2 title
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </AccordionPanel>
      </AccordionItem> */}
    </Accordion>
  );
};

export default Accordian;
