import React, { useEffect } from "react";

import { BlogContent as blogContent } from "utils/constants";

import { useNavigate } from "react-router-dom";

// import FeaturesBig from "components/FeatureImages";
// import FeaturesGrid from "components/FeatureGrid";
// import Gallery from "components/Gallery";
// import Hero from "components/Hero";
// import LogoCloud from "components/LogoCloud";
// import { AspectRatio, Box, Heading, Image, Text } from "@chakra-ui/react";
// import Footer from "components/Footer";
// import BlogBox from "components/BlogBox";
import CardView from "components/Card";

function Blog() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  // const [isBlogSelected, setIsBlogSelected] = useState(false);
  // const [selectedBlogContent, setSelectedBlogContent] = useState({});
  const viewMoreHandler = (item) => {
    // setIsBlogSelected(true);
    navigate(`/viewBlog/${item.id}`);
    // setSelectedBlogContent(item);
  };

  return (
    <>
      {/* {!isBlogSelected && ( */}
      <CardView data={blogContent} onButtonClick={viewMoreHandler} />
      {/* )} */}
      {/* <Gallery /> */}
      {/* {isBlogSelected && (
        <BlogBox
          header={selectedBlogContent?.header}
          description={selectedBlogContent?.description}
          images={selectedBlogContent?.images}
          subContent={selectedBlogContent?.subContent}
          // key={index}
        />
      )} */}
    </>
  );
}

export default Blog;
