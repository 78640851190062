import React, { useEffect } from "react";
// import { Container, Heading } from "@chakra-ui/react";

const Eula = () => {

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    paragraph: {
      marginBottom: '15px',
      textAlign: 'justify',
    },
    h2: {
      fontSize: '2rem',
      marginBottom: '10px',
      borderBottom: '0.5px solid #333',
      paddingBottom: '5px',
      fontWeight: '600', // Semi-bold
    },
    link: {
      color: '#007bff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <div style={styles.container}>
      <h1 style={styles.title}>End-User License Agreement (EULA) of Aarith's Software as a Service</h1>

      <div style={styles.section}>
        {/* <h2 style={styles.h2}>Introduction</h2> */}
        <p style={styles.paragraph}>
          This End-User License Agreement ("EULA") is a legal agreement between you and Imnemosyne Technologies Private Limited, a company incorporated under the Indian Companies Act of 2013, bearing Corporate Identification Number (CIN) U63999MH2023PTC415388 and having its registered office in Bengaluru, Karnataka, India.
        </p>
        <p style={styles.paragraph}>
        This EULA agreement governs your access and use of "Aarith" online software ("Software"), whether subscribed either directly from Imnemosyne Technologies Private Limited or indirectly through a Imnemosyne Technologies Private Limited authorized reseller or distributor (a "Reseller").
        </p>
        <p style={styles.paragraph}>
        Please read this EULA agreement carefully before completing the access/installation process and using the Software. It provides a license to use the Software and contains warranty information and liability disclaimers.
        </p>
        <p style={styles.paragraph}>If you register for a free trial of the Software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the Software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>
        <p style={styles.paragraph}>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>1. License Grant</h2>
        <p style={styles.paragraph}>
          Imnemosyne Technologies Private Limited hereby grants you a personal, non-transferable, non-exclusive license to use the Software on your devices in accordance with the terms of this EULA agreement.
        </p>
        <p style={styles.paragraph}>
          You are permitted to access the Software from a PC, laptop, mobile or tablet under your control via any Internet web browser. You are responsible for ensuring your device meets the minimum requirements of the Software.
        </p>
        <p style={styles.paragraph}>
          You expressly understand and acknowledge that the Software and the services are being provided on a completely "as is" basis without any representations and warranties regarding any accuracy or fitness of purpose.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>2. Subscription Fees and Payment Terms</h2>
        <p style={styles.paragraph}>
          The then existing subscription fee and applicable payment terms shall be displayed on the website from time to time, or in certain cases may be discussed separately and agreed in writing.
        </p>
        <p style={styles.paragraph}>
          You shall be able to use the Software only based on successful subscription fees payment via such mechanisms as may be in effect at the applicable point in time.
        </p>
        <p style={styles.paragraph}>
          Imnemosyne Technologies Private Limited sole and complete liability to you under this agreement due to any reason whatsoever shall be the refund of the then remaining prorated subscription fee.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>3. Non-permitted Activities</h2>
        <p style={styles.paragraph}>
          - Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things
        </p>
        <p style={styles.paragraph}>
          - Upload any viruses or malware or any other objectionable material (including pornography or Trojan horses)
        </p>
        <p style={styles.paragraph}>
          - Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose
        </p>
        <p style={styles.paragraph}>
          - Allow any third party to use the Software on behalf of or for the benefit of any third party
        </p>
        <p style={styles.paragraph}>
          - Use the Software in any way which breaches any applicable local, national or international law
        </p>
        <p style={styles.paragraph}>
          - Use of the Software for any purpose that Imnemosyne Technologies Private Limited considers is a breach of this EULA agreement.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>4. Intellectual Property and Ownership</h2>
        <p style={styles.paragraph}>
          Imnemosyne Technologies Private Limited shall at all times retain ownership of the Software as originally accessed/downloaded by you and all subsequent access/downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Imnemosyne Technologies Private Limited.
        </p>
        <p style={styles.paragraph}>
          Aarith (and their associated logos) are trademarks of Imnemosyne Technologies Private Limited and cannot be used anywhere for any purpose whatsoever by you.
        </p>
        <p style={styles.paragraph}>
          Imnemosyne Technologies Private Limited reserves the right to grant licences to use the Software to any party, including your competitors.
        </p>
        <p style={styles.paragraph}>
          Insofar as any data is uploaded by you in the Software, you hereby represent and warrant that either you are the owner of such data or alternatively you have the necessary permission and consent from the end-owner to upload that data. In all circumstances you alone shall be the controller and processor of any data that you upload onto the Software. You alone shall always be responsible and liable for controlling and/or processing any data and you shall always keep us harmless and indemnified in the event of any claims, notices, statutory proceedings, legal actions either from any end user or from any government authority or court of law of any country.
        </p>
        <p style={styles.paragraph}>
          Imnemosyne Technologies Private Limited shall have the unlimited right to utilise the knowledge derived from your usage-reports on an anonymized and aggregate basis.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>5. Termination</h2>
        <p style={styles.paragraph}>
          This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time by using online account termination features, or by providing a written notice to Imnemosyne Technologies Private Limited.
        </p>
        <p style={styles.paragraph}>
          It will also terminate immediately if you fail to comply with any term of this EULA agreement or fail to make the necessary applicable payment of the subscription fees.
        </p>
        <p style={styles.paragraph}>
          Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. Termination of the service shall also imply the deletion of the account as well as of all the data within that account.
        </p>
        <p style={styles.paragraph}>
          The provisions that by their nature continue and survive will survive any termination of this EULA agreement.
        </p>
        <p style={styles.paragraph}>
          Aarith will store data provided by the client for a period of up to 2 years from the date of collection, at no additional cost. After the expiration of 2 years, any request for storage beyond this period will be subject to additional charges as mutually agreed upon by both parties. Aarith reserves the right to delete or dispose of the data after the 2-year storage period, unless otherwise agreed upon in writing by the client. The client shall be responsible for notifying Aarith in writing of any storage extension requests prior to the expiration of the 2-year period.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>6. Governing Law</h2>
        <p style={styles.paragraph}>
          This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of India without reference to its conflict of laws rules, with courts at Bhandara having exclusive jurisdiction.
        </p>
        <p style={styles.paragraph}>
          It will always be your obligation to ensure that the usage and access of the Software does not violate any local laws and customs of your country.
        </p>
        <p style={styles.paragraph}>
          This EULA agreement shall be final and binding on the Parties and it constitutes the entire understanding between Parties in respect of the subject matter hereof and supersedes all prior negotiations, discussions and/or documents exchanged between Parties.
        </p>
      </div>
    </div>
  );
};

export default Eula;
