import React from "react";

import {
  Box,
  Button,
  Circle,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import HoverBadge from "components/HoverBadge";
import Parallax from "components/Parallax";
import { Orange, Purple } from "utils/constants";

function Hero() {
  return (
    <Box bg={Purple}>
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        pt={{ base: "3rem", md: "4.75rem" }}
        pb={{ base: "3rem", xl: "6.25rem" }}
      >
        <Stack
          direction={{ base: "column", xl: "row" }}
          justifyContent={{ base: "start", md: "center", xl: "space-between" }}
          alignItems={{ base: "center", xl: "end" }}
          spacing={{ base: "3rem", xl: "7rem" }}
        >
          {/* Text */}
          <VStack
            alignItems={{ base: "start", md: "center", xl: "start" }}
            textAlign={{ base: "start", md: "center", xl: "start" }}
            spacing="3rem"
          >
            <VStack spacing="1rem">
              <Heading as="h1" size="h1" color="white">
                Strategic Insights. Simplified.
              </Heading>
              <Text color="white" lineHeight="1.5">
                Track revenue and promotions across all channels and infer
                granular level actionable business, customer and competitor
                insights with Aarith.
              </Text>
            </VStack>
            <Wrap spacing="1.875rem">
              <WrapItem>
                <Button
                  backgroundColor={Orange}
                  // colorScheme={Orange}
                  // colorScheme="blue"
                >
                  Get Started
                </Button>
              </WrapItem>

              <WrapItem>
                <Button variant="outline" colorScheme="white">
                  Know More
                </Button>
              </WrapItem>
            </Wrap>
          </VStack>
          {/* Image */}
          <Box p={{ base: "1rem", md: "0" }}>
            <Box position="relative">
              <Image
                src="assets/images/hero/desktop_screen.png"
                // src="assets/images/hero/hero-image-big.png"
                alt="Consilated Dashboard"
              />
              <HoverBadge position="absolute" right="-1rem" top="-1rem">
                <Circle size="6px" bg="red" />
                <Text>Live</Text>
              </HoverBadge>
              <Parallax position="absolute" left="-3rem" bottom="-2.5rem">
                <Image
                  borderRadius={"xl"}
                  src="assets/images/hero/mobile_screen.png"
                  maxW={["2rem", "2rem", "2rem", "10rem", "auto"]}
                  shadow="2xl"
                  alt="Hero image"
                />
              </Parallax>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default Hero;
