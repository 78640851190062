import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import PricingBox from "components/PricingBox";
import { useLocation } from "react-router-dom";
import { Grey, Orange, Purple, modulesContent } from "utils/constants";
import { Card, CardGroup } from "react-bootstrap";
import { CheckCircle } from "phosphor-react";
import {
  ArrowCircleRight,
  BusinessCenterSharp,
  RunningWithErrors,
  SupportAgentSharp,
} from "@mui/icons-material";

const Insights = () => {
  const location = useLocation();

  // const [productIndex, setProductIndex] = useState(0);
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    const productObj = modulesContent[0];
    setProductDetails(productObj);
  }, []);

  const getIconView = (boxHeader) => {
    if ("Consumer" === boxHeader) {
      return (
        <SupportAgentSharp
          style={{
            width: "20%",
            height: "20%",
            color: `${Purple}`,
          }}
          fontSize="large"
        />
      );
    }
    if ("Business" === boxHeader) {
      return (
        <BusinessCenterSharp
          style={{
            width: "20%",
            height: "20%",
            color: `${Purple}`,
          }}
          fontSize="large"
        />
      );
    }
    return (
      <RunningWithErrors
        style={{
          width: "20%",
          height: "20%",
          color: `${Purple}`,
        }}
        fontSize="large"
      />
    );
  };

  return (
    <>
    <div style={{backgroundColor:`${Purple}`}} >
      <Stack spacing={5} marginY={5} paddingX={[5, 0]} >
        <VStack alignItems="center" mt={'8px'} w="full">
          <Heading py={'10px'} color={'White'}> {`धीति`} - {productDetails?.header}</Heading>

          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              paddingBottom: "10px",
            }}
          >
            <Text fontWeight={"semibold"} fontSize={"lg"} color={"white"}>
              {productDetails?.subHeader}
            </Text>
          </div>
        </VStack>
      </Stack>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {productDetails?.boxValues?.map((item, index) => (
          <Stack
            spacing={2}
            border="3px solid"
            m={"2%"}
            width={"20%"}
            borderColor={Orange}
            borderRadius="0.7rem"
            p={4}
            h={"350px"}
            backgroundColor="white"
            position="relative"
          >
            {getIconView(item?.name)}

            <Text fontSize={"xl"}>{item?.name}</Text>
            <Divider borderColor="blackAlpha.500" />
            {/* {console.log(productDetails)} */}
            {item?.features?.map((item, index) => (
              <List flex="1">
                <ListItem fontSize={"lg"} key={Math.random()}>
                  <ListIcon as={CheckCircle} color="gray.400" />
                  {item}
                </ListItem>
              </List>
            ))}
          </Stack>
        ))}
      </div>

      {/* <Divider mt={"2%"} borderColor="gray.400" /> */}
      
    {/* </div> */}
    <Container
        centerContent
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "1.5rem", md: "2.5rem" }}
      >
        <Heading
          as="h2"
          size="h2"
          // color="white"
          color={"black"}
          pb={{ base: "1rem", md: "1rem" }}
          textAlign={{ base: "start", md: "center" }}
        >
          {productDetails?.subFooterHeader}
        </Heading>
        <UnorderedList
          color={"black"}
          // color={"white"}
          fontSize={"xl"}
          fontFamily="revert"
        >
          {productDetails?.subFooterListItems?.map((item, index) => (
            <ListItem>{item}</ListItem>
          ))}
        </UnorderedList>
      </Container>
    </>
  );
};

export default Insights;
