import React from "react";
import {
  Box,
  Stack,
  HStack,
  Text,
  Heading,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { ArrowCircleRight, CheckCircle } from "phosphor-react";

//   import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";
// import { ArrowCircleRight, CheckCircle } from "phosphor-react";
import PropTypes from "prop-types";
import { Orange } from "utils/constants";

const PricingBox = ({
  popular,
  name,
  price,
  info = "",
  features = [],
  subHeader,
  showButton = true,
  showPrice = true,
  showSubHeader = true,
}) => {
  return (
    <Stack
      spacing={2}
      border="3px solid"
      borderColor={popular ? Orange : "gray.300"}
      borderRadius="0.7rem"
      p={4}
      h={showSubHeader ? "400px" : "300px"}
      backgroundColor="white"
      position="relative"
    >
      {popular && showPrice && (
        <Box
          position="absolute"
          top="0"
          right="0"
          // backgroundColor="teal.300"
          backgroundColor={Orange}
          color="white"
          paddingX={2}
          paddingY={1}
          borderRadius="0 0 0 0.7rem"
          fontSize="0.8rem"
        >
          POPULAR
        </Box>
      )}
      <Text fontWeight={"bold"} textTransform="uppercase">
        {name}
      </Text>
      {showPrice && (
        <HStack>
          <Heading>{price ?? ""}</Heading>
          {price && (
            <Box as="span" color="gray.600" fontSize="sm">
              / year
            </Box>
          )}
        </HStack>
      )}
      {showSubHeader && <Text>{subHeader}</Text>}
      <Divider borderColor="blackAlpha.500" />
      <List flex="1">
        {features.map((feat) => (
          <ListItem key={Math.random()}>
            <ListIcon as={CheckCircle} color="gray.400" />
            {feat}
          </ListItem>
        ))}
      </List>
      <Box>
        {showButton && (
          <Button
            variant="solid"
            size="sm"
            width="100%"
            rightIcon={<ArrowCircleRight size={32} />}
            borderRadius={0}
            display="flex"
            justifyContent="space-between"
            backgroundColor={popular ? Orange : "gray.400"}
            _hover={{
              backgroundColor: `${Orange}`,
            }}
            color="white"
          >
            Buy
          </Button>
        )}
        <Text fontSize="xs">{info}</Text>
      </Box>
    </Stack>
  );
};
PricingBox.propTypes = {
  name: PropTypes.string.isRequired,
  popular: PropTypes.bool,
  price: PropTypes.string,
  info: PropTypes.string,
  features: PropTypes.array.isRequired,
};

export default PricingBox;
