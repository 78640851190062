import React from "react";

import {
  Broadcast,
  ChartLine,
  CurrencyCircleDollar,
  DesktopTower,
  UploadSimple,
  VideoCamera,
} from "phosphor-react";
import {
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import FeatureCell from "./FeatureCell";
import { Orange, Purple } from "utils/constants";
import { CurrencyRupee } from "@mui/icons-material";

const features = [
  {
    icon: <UploadSimple />,
    heading: "Product Performance Monitoring",
    text: "Get SKU level insights segmented across channel, campaign, location, etc.",
  },
  {
    icon: <VideoCamera />,
    heading: "Single-view campaign performance",
    text: "Multi-channel campaign monitoring and ROI analysis.",
  },
  {
    icon: <Broadcast />,
    heading: "Marketplace Monitoring",
    text: "Manage Product Listing, Customer review, Tags on Amazon and Flipkart.",
  },
  {
    // icon: <CurrencyCircleDollar />,
    icon: <CurrencyRupee />,
    heading: "Social Media Tracking",
    text: "Real-time insights on engagements, and growth on Meta/ Google platforms.",
  },
  {
    icon: <ChartLine />,
    heading: "Competitor Activity Alerts",
    text: "Uncover competitor moves, track market shifts, and refine your strategy.",
  },
  {
    icon: <DesktopTower />,
    heading: "WhatsApp Integration",
    text: "Connect effortlessly, streamline dropped eCommerce transaction.",
  },
  // {
  //   icon: <DesktopTower />,
  //   heading: "Reconciliation",
  //   text: "Streamline transactions and reconcile payments effortlessly.",
  // },
];

function FeatureGrid() {
  return (
    <Container
      maxW={{ base: "container.sm", xl: "container.xl" }}
      py={{ base: "2rem", md: "5.5rem" }}
    >
      <VStack
        spacing={{
          base: "3rem",
        }}
      >
        {/* Heading */}
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
        >
          <Heading color={Purple} as="h1" size="h1">
            Our Solution. Your Way.
          </Heading>
          <Text lineHeight="1.5">
            Power packed features to solve your practical everyday hassles,
            helping you stay better informed.
          </Text>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 3 }}
          spacing={{ base: "3rem", md: "5rem", xl: "7.5rem" }}
          maxW="57rem"
        >
          {features.map((feature, index) => (
            <FeatureCell key={`feature-${index}`} {...feature} />
          ))}
        </SimpleGrid>
        <Button
          marginTop={"0px"}
          backgroundColor={Orange}
          // colorScheme={Orange}
          // colorScheme="orange"
          color="black"
          // color="#ef7f1a"
        >
          Book a Demo
        </Button>
      </VStack>
    </Container>
  );
}

export default FeatureGrid;
