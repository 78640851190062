import React, { useEffect } from "react";
import { Container, Heading } from "@chakra-ui/react";
import Accordian from "components/Accordian";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    paragraph: {
      marginBottom: '15px',
    },
    contactDetails: {
      marginTop: '20px',
    },
    contactInfo: {
      fontSize: '1.1rem',
      marginBottom: '10px',
    },
    link: {
      color: '#007bff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    h2: {
      fontSize: '1.1rem',
      // marginBottom: '10px',
      // borderBottom: '2px solid #333',
      // paddingBottom: '5px',
      fontWeight:'550',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy</h1>

        <h2 style={styles.h2}>Imnemosyne Technologies Private Limited (“Aarith”)</h2>
      <div style={{marginBottom: '20px',marginTop:'5px'}}>
        <p style={styles.paragraph}>
          Aarith, a company registered under the Companies Act, 2013 with its registered office at Plot No.17, Vrundawan Colony, Parsodi, Bhandara, Maharashtra, 441906, understands the importance of protecting and safeguarding your privacy. We have therefore established and created this privacy policy (“Privacy Policy”) to inform you about the type of personal information we collect from you, how we use it, whom we share, how do we store and protect and what options you have to limit our use of the personal data related to you. In this Privacy Policy, “personal data” means information or pieces of information that could allow you to be identified as a person (“Personal Data'').
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>1. APPLICABILITY</h2>
        <p style={styles.paragraph}>
          This Privacy Policy applies to your use of our website <a href="https://www.aarith.com/" style={styles.link}>https://www.aarith.com/</a> and all products and services that we offer you via our website (together called “Website”).
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>2. CONSENT</h2>
        <p style={styles.paragraph}>
          Aarith will not collect, use or disclose your personal data without your prior consent. Therefore, we ask you to agree to this Privacy Policy before you can use our Website. By accessing or using our Website or by otherwise giving us your information, you will be deemed to have the capacity to enter into a legally binding contract as per the jurisdiction from which you are accessing the Website or giving us the information and you consent to the collection, storage, and processing of your personal information as stated in this Privacy Policy. You can withdraw your consent at any time by sending email to <a href="mailto:ecometsmb@gmail.com" style={styles.link}>ecometsmb@gmail.com</a> or by using the form available on this Website.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>3. COLLECTION AND USE OF PERSONAL DATA</h2>
        <p style={styles.paragraph}>
          <ul>
            <li>Upon prior consent, We may collect, store, use and disclose information about individuals who may constitute Personal Data to provide our products and services for lawful, explicit and legitimate purposes and for further processing of Personal Data consistent with those purposes. When you provide information that enables us to respond to your queries, we will, wherever permissible by relevant laws, collect the information for the purposes described in this Privacy Policy.</li>
            <li>Aarith will not collect any Personal Data about you unless you voluntarily provide this information to us. Some of this information that you may provide will identify you personally, either alone or in combination with other information available to us.</li>
            <li>We may collect Personal Data you choose to provide when you fill forms on or raise a query through our Website, or send emails, call us or write to us. If you contact us, we may keep a record of such correspondence. We may also collect Personal Data by asking you to complete surveys that we would use for research purposes, although you have a right not to respond to them.</li>
            <li>We may collect your personal information when you visit the Website. The list of Personal Data collected through the Website are notified to you at the time of granting permission to share the relevant information. Such information may include log data, cookie data, device information, demographic information, behavioural information and the sensitive personal data. We may use automated technologies including (where available) the use of web server logs to collect IP addresses, device details, browser types, cookies and web beacons which might help us in system administration, to filter traffic and improve the effectiveness of Website and our marketing activities.</li>
            <li>When you provide us your mobile phone number, you consent to the use of your mobile phone number for the purposes identified in this Privacy Policy. If you choose to refrain from receiving any call/text notifications from us on your mobile, we will not be using your mobile number for such purposes unless required to meet legal requirements or legitimate business purposes.</li>
          </ul>
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>4. SHARING OF PERSONAL DATA.</h2>
        <p style={styles.paragraph}>
          <ul>
            <li>We process your personal data through AWS and google cloud which is the main cloud hosting provider of Aarith</li>
            <li>Where required or permitted by law, information may be provided to others, such as statutory authorities, government institutions, regulators and law enforcement agencies for compliance with legal requirements.</li>
            <li>We do not share personal information about you with third parties, except: (a) to provide products or services you have requested; (b) when we have your permission: or (c) under the following circumstances:</li>
            <li>We may provide the information to trusted entities who work on behalf of or with Aarith as per the provisions of data privacy laws and under strict confidentiality agreements. These entities may use your Personal Data to help us communicate with you for legitimate business purposes. However, these companies do not have any independent right to further share or disseminate this information.</li>
            <li>We may use the information to respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
            <li>We may share information where we believe it is necessary in order to investigate, prevent, or take action against any illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by  national and international legislations.</li>
            <li>We may share information where we believe it is necessary in order to protect or enforce our rights, usage terms, intellectual or physical property or for the safety of Aarith or associated parties.</li>
            <li>From time to time, we may consider corporate transactions such as a merger, acquisition, reorganization, asset sale, or similar. In these instances, we may transfer or allow access to information to enable the assessment and undertaking of that transaction. If we buy or sell any business or assets, personal data may be transferred to a third party involved in the transaction. </li>
          </ul>
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>5.  DATA STORAGE AND RETENTION</h2>
        <p style={styles.paragraph}>
          <ul>
            <li>Your personal data is stored in databases of our trusted third-party service providers. We contractually require those third parties to keep data secure and confidential and we do not allow them to disclose your Personal Data to others without our authorization, or to use it for their own purposes.</li>
            <li>Aarith will retain your Personal Data pursuant to the business purposes and in line with our retention policies[1]  or as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements.</li>
          </ul>
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>6. INDIVIDUAL RIGHTS</h2>
        <p style={styles.paragraph}>
        You may have certain rights relating to your Personal Data provided for under applicable law. These are the right to:
          <ul>
            <li>Request access to your Personal Data and the processing activities on the personal data.</li>
            <li>Request that your Personal Data is rectified if it is inaccurate or incomplete</li>
            <li>Request erasure of your Personal Data in certain circumstances.</li>
            <li>Request restriction of the processing of your Personal Data in certain circumstances.</li>
            <li>Object to the processing of your Personal Data in certain circumstances.</li>
            <li>Receive your Personal Data provided to us in a structured, commonly used and machine-readable format in certain circumstances.</li>
            <li>Lodge a complaint with a relevant authority.</li>
            <li>Withdraw your consent provided at any time by contacting us.</li>
            <li>You have the option to subscribe/ opt-in to receive our brochures, newsletters, marketing and research related materials.</li>
            <li>To exercise the rights outlined above in respect of your Personal Data please contact the details available in the contact section below. We will endeavour to respond to you within 3 working days.</li>
            <li>Please note that we may need to retain certain Personal Data for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion.</li>
          </ul>
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>7. PROTECTION OF MINOR’s INFORMATION</h2>
        <p style={styles.paragraph}>
        The Website is not directed to children under the age of eighteen (18) years. We do not knowingly solicit Personal Data from or market to anyone under eighteen (18) years of age. If you believe that we have received information from a minor, please notify us immediately and we will ensure that reasonable efforts will be made by us to delete such information from our database.
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>8. EXTERNAL LINKS</h2>
        <p style={styles.paragraph}>
        Our Website may contain links to other sites including social media links, event sites etc. whose information practices may be different from ours/. You should read such third party’s websites' privacy notices prior to interacting or providing any of your Personal Information. Aarith does not control those sites or their privacy practices. We do not endorse or make any representations about third-party websites.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>9. COOKIES AND OTHER TECHNOLOGIES</h2>
        <p style={styles.paragraph}>
        Our Website uses cookies and similar technologies to facilitate proper functioning of our Websites and to achieve performance. A cookie is a text-only string of information that a website transfers to the cookie file of the browser on your computer’s hard disk. so that website can remember who you are.
        </p>
      </div>


      <div style={styles.section}>
        <h2 style={styles.h2}>10. REASONABLE SECURITY PRACTICES & PROCEDURES</h2>
        <p style={styles.paragraph}>
          <ul>
            <li>We have put in place reasonable security practices and procedures consisting of managerial, technical, operational and physical security controls that comply with all applicable laws and regulations to protect your Personal Data from unauthorized or inappropriate access, loss, alteration, disclosure, damage and destruction.</li>
            <li>We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator/supervisory authority of any suspected breach where we are legally required to do so. Users are responsible for maintaining the secrecy of their own passwords. If you have reason to believe that your interaction with us is no longer secure or a (for example, if you feel that the security of any account you might have with us has been compromised) or a Personal Data breach has occurred, please immediately notify us by contacting us at ecometsmb@gmail.com.Please refer to our breach management policy for more details.</li>
          </ul>
        </p>
      </div>
      <div style={styles.section}>
        <h2 style={styles.h2}>11. CHANGES TO THIS POLICY</h2>
        <p style={styles.paragraph}>
        Please note that this policy may change from time to time. Please visit our website to keep abreast of any changes to the policy.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.h2}>12. GRIEVANCE OFFICER AND CONTACT DETAILS</h2>
        <p style={styles.paragraph}>
        If you have any Personal Data-related complaints, data protection concerns and any communications regarding enforcement of your privacy rights or questions regarding our privacy policy or the practices described herein, you may contact us at:
        </p>
      </div>

      {/* More sections can be added similarly */}

      <div style={styles.contactDetails}>
        {/* <h2 style={styles.h2}>Contact Details</h2> */}
        <p style={styles.contactInfo}>Aarith</p>
        <p style={styles.contactInfo}>+91-8657952260</p>
        <p style={styles.contactInfo}><a href="mailto:ecometsmb@gmail.com" style={styles.link}>ecometsmb@gmail.com</a></p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
