export const Purple = "#562869";

export const Orange = "#ef7f1a";

export const Grey = '#EEEEEE';

export const Teal = '#009688'

export const modulesContent = [
  {
    id: "0",
    header: "Insights",
    subHeader: `Unlock the power of data-driven decision-making with Aarith's Insights module. Make informed decisions based on business performance, customer behavior understanding, and outpace the competition in today's dynamic market landscape.`,
    boxValues: [
      {
        name: "Business",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Real-time Sales Analytics",
          "Consolidated Insights",
          "Granular level revenue view",
        ],
      },
      {
        name: "Consumer",
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        popular: true,
        features: [
          "Customer Segmentation",
          "Behavioral Analysis",
          "Purchase History Tracking",
          "Sentiment Analysis",
        ],
      },
      {
        name: "Competitor",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Channel Strength",
          "Paid v/s organic growth",
          "Pricing Strategy",
          "Customer Sentiments",
        ],
      },
    ],
    subFooterHeader: "How Dhiti Benefits?",
    subFooterListItems: [
      "Improved Decision-Making",
      "Enhanced Operational Efficiency",
      "Strategic Market Expansion",
      "Proactive Issue Resolution",
      "Increased Customer Loyalty",
      "Targeted Marketing Campaigns",
      "Higher Conversion Rates",
    ],
  },

  {
    id: "1",
    header: "Management",
    subHeader: `Welcome to Aarith's Management Module – the nerve center for controlling and enhancing every aspect of your retail brand's online presence. With seamless integration and intuitive controls, take charge of Catalogs, Ads, and Channels effortlessly.`,
    boxValues: [
      {
        name: "Catalog Management",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Real-time Sales Analytics",
          "Consolidated Insights",
          "Granular level revenue view",
        ],
      },
      {
        name: "Consumer",
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        popular: true,
        features: [
          "Customer Segmentation",
          "Behavioral Analysis",
          "Purchase History Tracking",
          "Sentiment Analysis",
        ],
      },
      {
        name: "Competitor",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Channel Strength",
          "Paid v/s organic growth",
          "Pricing Strategy",
          "Customer Sentiments",
        ],
      },
    ],
    // readItems: [
    //   {
    //     name: "Catalog Management: Your Products, Perfected",
    //     description:
    //       "Effortlessly manage and streamline the process from creation to update across all channels, ensuring accurate and up-to-date product information.",
    //   },
    //   {
    //     name: "Ad Management: Precision in Every Click",
    //     description:
    //       "From creation to analysis, optimize your campaigns for maximum impact. Leverage AI-driven insights to target the right audience, refine your messaging, and boost ROI.",
    //   },
    //   {
    //     name: "Channel Management: Expand Your Horizons",
    //     description:
    //       "From e-commerce platforms to social media, centralize control and ensure a consistent brand image. Seamlessly navigate presence across marketplaces, websites, and physical POS with Aarith.",
    //   },
    // ],
    subFooterHeader: "How Kriya Benefits?",
    subFooterListItems: [
      "Consistent Product Information",
      "Time and Cost Efficiency",
      "Campaign Creation and Optimization",
      "Target Audience Insights",
      "Performance Analytics",
      "A/B Testing",
      "Budget Control",
      "Efficient Inventory Management",
      "Real-Time Performance Insights",
    ],
  },

  {
    id: "2",
    header: "Engagement",
    subHeader: `Transform every interaction into an opportunity with Aarith's Engagement Module. Seamlessly interact with your audience across Email, Social Media, and WhatsApp, cultivating meaningful relationships and driving brand loyalty.`,
    boxValues: [
      {
        name: "Email Engagement",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Segmented Email Campaigns",
          "Automation and Workflow",
          "Analytics and Reporting",
          "A/B Testing",
        ],
      },
      {
        name: "Social Media Engagement",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Multi-Platform Posting",
          "Social Listening",
          "Performance Analytics",
          "Content Scheduling",
        ],
      },
      {
        name: "WhatsApp Engagement",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Order Updates and Tracking",
          "Customer Support Integration",
          "Personalized Promotions",
          "Analytics Dashboard",
        ],
      },
    ],
    subFooterHeader: "How Sanchaar Benefits?",
    subFooterListItems: [
      "Higher Open and Click Rates",
      "Improved Customer Retention",
      "Time and Cost Efficiency",
      "Data-Driven Optimization",
      "Increased Brand Visibility",
      "Improved Customer Satisfaction",
      "Increased Conversion Rates",
      "Streamlined Customer Support",
      "Enhanced Brand Loyalty",
    ],
  },

  {
    id: "3",
    header: "Forecasting",
    subHeader: `Elevate your business to new heights by turning predictions into profits. Aarith's Forecasting Module is your key to instantly anticipate market trends, optimizing pricing strategies, and maintaining an efficient inventory flow.`,
    boxValues: [
      {
        name: "Catalog Management",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Real-time Sales Analytics",
          "Consolidated Insights",
          "Granular level revenue view",
        ],
      },
      {
        name: "Consumer",
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        popular: true,
        features: [
          "Customer Segmentation",
          "Behavioral Analysis",
          "Purchase History Tracking",
          "Sentiment Analysis",
        ],
      },
      {
        name: "Competitor",
        popular: true,
        // price: "25,000",
        // subHeader: "Startups & Independent Retailers",
        features: [
          "Channel Strength",
          "Paid v/s organic growth",
          "Pricing Strategy",
          "Customer Sentiments",
        ],
      },
    ],
    // readItems: [
    //   {
    //     name: "Pricing Control",
    //     description:
    //       "Align pricing strategies with inventory levels, ensuring a harmonious balance that maximizes sales and minimizes costs.",
    //   },
    //   {
    //     name: "Discount Predictions",
    //     description:
    //       "Leverage advanced algorithms and historical data to forecast optimal discount rates, ensuring your promotions drive sales without compromising your bottom line.",
    //   },
    //   {
    //     name: "Inventory Forecasting",
    //     description:
    //       "Predict demand fluctuations, seasonal trends, and external factors to ensure your shelves are stocked with the right products at the right time.",
    //   },
    // ],
    subFooterHeader: "How Anuman Benefits?",
    subFooterListItems: [
      "Cost Reduction through Efficient Inventory Management",
      "Minimized Stockouts and Overstock Situations",
      "Improved Order Accuracy",
      "Demand Prediction",
      "Seasonal Trend Analysis",
      "Competitive Benchmarking",
      "Improved Profit Margins",
    ],
  },
];

export const BlogContent = [
  {
    id: "0",
    header:
      "Unveiling the Power of eCommerce Analytics: Igniting Business Growth",
    description: `In the dynamic landscape of digital commerce, businesses are
      continually seeking ways to gain a competitive edge and foster
      sustainable growth. In this pursuit, eCommerce analytics emerges as
      a game-changer, offering invaluable insights that can reshape
      strategies and elevate performance. Let's delve into the profound
      impact of eCommerce analytics on business growth and how it has
      become an indispensable tool for success.`,
    subContent: [
      {
        header: "Understanding eCommerce Analytics",
        content: `ECommerce analytics involves the collection, analysis, and
  interpretation of data generated through online sales and customer
  interactions. From website traffic and customer behavior to
  transaction patterns, this data holds the key to unlocking a wealth
  of information that businesses can leverage to make informed
  decisions.`,
      },

      {
        header: "Unveiling Consumer Behavior",
        content: `One of the primary advantages of eCommerce analytics is its ability to unveil intricate details about consumer behavior. By scrutinizing data related to page views, click-through rates, and time spent on a website, businesses can gain insights into customer preferences, popular products, and potential pain points. Armed with this information, companies can tailor their marketing strategies, enhance user experience, and optimize product offerings to better align with customer expectations.`,
      },

      {
        header: "Personalization for Enhanced Customer Engagement",
        content: `Personalization has become a cornerstone of successful eCommerce ventures, and analytics plays a pivotal role in achieving this. Through data-driven insights, businesses can create personalized shopping experiences, recommend products based on past purchases, and implement targeted marketing campaigns. This not only fosters customer loyalty but also increases the likelihood of repeat business, driving overall growth.`,
      },
      {
        header: "Inventory Management and Demand Forecasting",
        content: `Effective inventory management is crucial for eCommerce success. Analytics empowers businesses to monitor product performance, assess stock levels, and forecast demand with precision. By understanding which products are gaining traction and predicting future trends, businesses can optimize their inventory, reduce excess stock, and ensure they meet customer demands promptly. This streamlined approach to inventory management contributes directly to cost savings and improved profitability.`,
      },
      {
        header: "Conversion Rate Optimization",
        content: `A high volume of website traffic is only valuable if it translates into conversions. eCommerce analytics enables businesses to identify bottlenecks in the sales funnel, analyze factors affecting conversion rates, and implement strategies to enhance the overall purchasing process. This iterative process of optimization leads to increased conversion rates, positively impacting revenue streams and business growth.`,
      },
      {
        header: "Performance Measurement and KPI Tracking",
        content: `Key Performance Indicators (KPIs) serve as benchmarks for business success. With eCommerce analytics, businesses can track and measure the effectiveness of various initiatives, marketing campaigns, and overall website performance. This data-driven approach allows for continuous improvement, helping businesses allocate resources strategically and focus on areas that contribute most to their growth objectives.`,
      },
      {
        header: "Conclusion",
        content: `In the era of digital commerce, harnessing the power of eCommerce analytics is no longer a choice but a necessity. The actionable insights derived from data analysis have a transformative impact on businesses, propelling them towards sustained growth, improved customer satisfaction, and heightened competitiveness. As technology continues to evolve, businesses that embrace and master eCommerce analytics will find themselves at the forefront of innovation and success in the ever-expanding digital marketplace.`,
      },
    ],
    images: [
      // {
      //   url: "https://www.freepik.com/free-photo/representation-user-experience-interface-design_94963703.htm",
      // },
    ],
  },

  {
    id: "1",
    header:
      "Harnessing the Power of Reviews: A Guide to Analyzing and Leveraging Customer Feedback for Business Growth",
    description: `In the digital age, customer reviews serve as a goldmine of insights, providing businesses with a direct line to their audience's opinions and experiences. The ability to effectively analyze and leverage these reviews can be a game-changer for business growth. Let's explore a step-by-step guide on how to harness the power of reviews and turn them into a catalyst for success.`,
    subContent: [
      {
        header: "Step 1: Collect Comprehensive Data",
        content: `Start by gathering reviews from various platforms where your business is listed. This includes your own website, third-party marketplaces, and social media channels. Create a centralized database to consolidate this information, making it easier to analyze trends and patterns.`,
      },

      {
        header: "Step 2: Categorize and Prioritize",
        content: `Organize the collected reviews into categories based on common themes. Prioritize issues or feedback that appear frequently or have a significant impact on customer satisfaction. Categorization allows you to identify key areas for improvement or celebration.`,
      },

      {
        header: "Step 3: Identify Trends and Patterns",
        content: `Analyze the data to identify trends and patterns within customer feedback. Look for recurring keywords, sentiments, and specific product or service aspects that customers consistently highlight. This deep dive provides valuable insights into what customers value most and areas where improvements can be made.`,
      },
      {
        header: "Step 4: Understand Customer Pain Points",
        content: `Take note of any pain points or challenges customers mention in their reviews. Understanding these pain points enables you to proactively address issues, enhance the customer experience, and potentially differentiate your business from competitors.`,
      },
      {
        header: "Step 5: Leverage Positive Feedback",
        content: `Identify positive sentiments and feedback within reviews. Use this positive reinforcement to showcase your strengths in marketing materials, product descriptions, and social media. Positive reviews can serve as powerful testimonials, building trust and credibility with potential customers.`,
      },
      {
        header: "Step 6: Respond Appropriately",
        content: `Engage with your customers by responding to their reviews, both positive and negative. Acknowledge their feedback, express gratitude, and, when applicable, outline steps taken to address any concerns. Responding shows a commitment to customer satisfaction and transparency, enhancing your brand's reputation`,
      },
      {
        header: "Step 7: Implement Changes Based on Feedback",
        content: `Use the insights gained from customer reviews to drive strategic improvements. Whether it's refining product features, optimizing customer service processes, or enhancing user experience, implementing changes based on feedback demonstrates a commitment to continuous improvement.`,
      },
      {
        header: "Step 8: Monitor Competitor Reviews",
        content: `Extend your analysis beyond your own reviews and monitor what customers are saying about your competitors. This broader perspective can reveal industry trends, customer expectations, and areas where your business can gain a competitive advantage.`,
      },
      {
        header: "Step 9: Incorporate Reviews into Marketing Strategies",
        content: `Integrate positive reviews into your marketing efforts. Feature them prominently on your website, in promotional materials, and across social media platforms. Positive reviews serve as powerful marketing tools, influencing potential customers and contributing to business growth.`,
      },
      {
        header: "Step 10: Iterate and Repeat",
        content: `Customer preferences and market dynamics evolve over time. Regularly revisit and reassess your review analysis process. Stay attuned to emerging trends, adapt your strategies, and continue leveraging customer feedback as a dynamic resource for sustained business growth.`,
      },
      {
        header: "Conclusion",
        content: `Analyzing and leveraging customer reviews is not just about managing feedback; it's a strategic approach to fueling business growth. By treating customer reviews as a valuable source of insights, businesses can make informed decisions, enhance customer satisfaction, and position themselves for long-term success in the competitive marketplace. Embrace the power of reviews, turning them into a driving force that propels your business to new heights`,
      },
    ],
    images: [
      {
        url: "https://img.freepik.com/free-photo/medium-shot-young-people-with-reviews_23-2149394417.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      },
      // {
      //   url: "https://img.freepik.com/free-photo/customer-experience-creative-collage_23-2149346506.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      // },
    ],
  },

  {
    id: "2",
    header: `Optimize your Ad spends by understanding Smart Bidding Strategies on Google Ads`,
    description: `In the fast-paced realm of digital advertising, the landscape is continually evolving, demanding a strategic approach to ensure optimal return on investment (ROI). Google Ads, with its sophisticated Smart Bidding strategies, has emerged as a game-changer for businesses aiming to maximize their advertising effectiveness. Let's delve into the world of Smart Bidding and explore how it can elevate your advertising efforts to new heights.`,
    subContent: [
      {
        header: "Understanding Smart Bidding",
        content: `Smart Bidding is a set of automated bidding strategies powered by machine learning algorithms. These strategies leverage vast amounts of data to make real-time bid adjustments, optimizing for different goals such as conversions, conversion value, clicks, or impression share. The primary objective is to enhance the performance of your Google Ads campaigns by bidding more efficiently in the auction.`,
      },

      {
        header: "1. Maximize Conversions",
        content: `One of the most popular Smart Bidding strategies is "Maximize Conversions." With this approach, Google Ads automatically sets bids to help you get the maximum number of conversions within your specified budget. The algorithm analyzes historical data, considers contextual factors like device, location, and time of day, and adjusts bids accordingly to drive optimal results.`,
      },

      {
        header: "2. Target CPA (Cost-Per-Acquisition)",
        content: `If your primary focus is achieving a specific cost-per-acquisition, the "Target CPA" Smart Bidding strategy is your ally. This approach aims to get as many conversions as possible while maintaining a target CPA that aligns with your budget constraints. The algorithm adapts to changing conditions in the auction to help you achieve your desired conversion cost.`,
      },
      {
        header: "3. Target ROAS (Return on Ad Spend)",
        content: `For businesses with a focus on revenue generation, the "Target ROAS" Smart Bidding strategy is a valuable tool. This approach optimizes bids to maximize the return on ad spend, considering the value of each conversion. By setting a target ROAS, advertisers can align their bidding strategy with specific revenue goals, ensuring a balance between investment and return`,
      },
      {
        header: "4. Enhanced Cost-Per-Click (eCPC)",
        content: `If you prefer a more hands-on approach while still leveraging some automation, the "Enhanced Cost-Per-Click" Smart Bidding strategy may be a suitable choice. This strategy allows you to maintain manual control over certain aspects of bidding while enabling Google Ads to adjust bids for clicks that are more likely to lead to conversions.`,
      },
      {
        header: "5. Target Impression Share",
        content: `For businesses prioritizing brand visibility, the "Target Impression Share" Smart Bidding strategy is instrumental. This approach focuses on ensuring your ads appear in a specified percentage of eligible auctions, maximizing your brand's exposure. It's particularly beneficial for businesses aiming to dominate specific market segments.`,
      },
      {
        header: "6. Seasonal Adjustments",
        content: `Smart Bidding also offers the flexibility to make seasonal adjustments to bidding strategies. This feature enables advertisers to anticipate and adapt to fluctuations in demand during peak seasons, holidays, or special events. By adjusting bids based on seasonality, businesses can capitalize on high-converting periods and maximize ROI.`,
      },
      {
        header: "Conclusion",
        content: `In the ever-evolving landscape of digital advertising, Smart Bidding strategies within Google Ads stand out as a powerful tool for advertisers seeking to maximize ROI. By harnessing the capabilities of machine learning algorithms, businesses can optimize bidding strategies, adapt to changing market conditions, and achieve their advertising goals with unparalleled precision. Embrace the power of Smart Bidding, and propel your Google Ads campaigns to new heights of efficiency and effectiveness in the competitive digital marketplace.`,
      },
    ],
    images: [
      {
        url: "https://img.freepik.com/premium-photo/people-using-ads-digital-marketing-concept-online-advertisement-ad-website-social-media-customer_10541-8232.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      },
    ],
  },

  {
    id: "3",
    header: `Effective Strategies for Boosting Organic Reach on Facebook`,
    description: `In the ever-evolving world of social media marketing, mastering the art of boosting organic reach on platforms like Facebook is essential for businesses aiming to maximize their online visibility without breaking the bank. Here, we'll explore a set of effective strategies to elevate your organic reach and foster meaningful engagement with your target audience.`,
    subContent: [
      {
        header: "1. Craft Compelling Content",
        content: `The cornerstone of any successful organic reach strategy is compelling content. Create content that resonates with your audience's interests, challenges, and aspirations. Utilize a mix of formats, including images, videos, infographics, and written posts, to cater to diverse preferences within your audience.`,
      },

      {
        header: "2. Understand Your Audience Insights",
        content: `Leverage Facebook's robust analytics tools to gain insights into your audience demographics, behaviors, and preferences. Understanding who your audience is and what they engage with allows you to tailor your content to their specific interests, increasing the likelihood of organic reach.`,
      },

      {
        header: "3. Consistent Posting Schedule",
        content: `Maintain a consistent posting schedule to keep your audience engaged. Regular, timely updates signal to Facebook's algorithm that your page is active and relevant. Experiment with different posting times to identify when your audience is most active and adjust your schedule accordingly.`,
      },
      {
        header: "4. Embrace Live Videos",
        content: `Facebook prioritizes live videos in users' news feeds, making them an excellent tool for increasing organic reach. Live videos encourage real-time engagement, and the platform often notifies followers when a live broadcast is in progress, helping you capture attention and drive immediate interaction.`,
      },
      {
        header: "5. Encourage Audience Participation",
        content: `Foster a sense of community by encouraging your audience to participate in discussions, polls, and contests. When users engage with your content by commenting, liking, or sharing, it signals to Facebook's algorithm that your content is valuable, leading to increased organic visibility.`,
      },
      {
        header: "6. Optimize Post Timing",
        content: `Timing is crucial in social media. Experiment with posting at different times to identify when your audience is most active. Additionally, consider the timing of trending topics or relevant events that align with your content, increasing the likelihood of your posts appearing in users' feeds.`,
      },

      {
        header: "7. Use Hashtags Wisely",
        content: `While Facebook isn't as hashtag-centric as some other platforms, strategic use of relevant hashtags can increase the discoverability of your content. Research and incorporate popular and industry-specific hashtags to broaden the reach of your posts.`,
      },
      {
        header: "8. Engage with Your Audience",
        content: `Respond promptly to comments, messages, and interactions on your page. Engagement is a two-way street, and active participation builds a sense of community. Facebook's algorithm recognizes pages that foster meaningful interactions, rewarding them with increased organic reach.`,
      },
      {
        header: "9. Share User-Generated Content",
        content: `Encourage your audience to create and share content related to your brand. User-generated content not only adds authenticity to your page but also extends your organic reach as users share their experiences with their networks.`,
      },
      {
        header: "10. Optimize Your Facebook Page",
        content: `Ensure that your Facebook page is fully optimized. This includes a compelling profile picture, a captivating cover photo, a concise and informative bio, and accurate contact information. A well-organized and visually appealing page encourages users to explore and engage.`,
      },
      {
        header: "Conclusion",
        content: `Mastering the art of boosting organic reach on Facebook involves a combination of strategic content creation, audience understanding, and active engagement. By consistently delivering value to your audience and optimizing your approach based on insights and analytics, you can navigate the evolving landscape of social media and foster organic growth for your brand. Embrace these effective strategies, and watch as your Facebook presence flourishes, creating lasting connections with your audience.`,
      },
    ],
    images: [
      {
        url: "https://img.freepik.com/free-photo/smiling-woman-holding-facebook-logo-with-his-friends-showing-thumbup-sign_23-2147847473.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      },
    ],
  },

  {
    id: "4",
    header: `Guide to Crafting Irresistible Offers and Deals for your shoppers on Marketplace`,
    description: `The Art of Crafting Irresistible Offers: Strategies to Captivate Marketplace Shoppers
      In the bustling world of online marketplaces, where choices abound and attention spans are fleeting, the ability to create irresistible offers is a key differentiator for businesses. Crafting deals that not only catch the eye but also prompt action requires a strategic approach. Let's delve into some effective strategies to craft offers that will leave a lasting impression on marketplace shoppers.
      `,
    subContent: [
      {
        header: "1. Know Your Audience Inside Out",
        content: `Understanding your target audience is the foundation of crafting compelling offers. Dive deep into their preferences, behaviors, and motivations. Tailoring your deals to align with the specific needs of your audience ensures that your offers resonate and stand out amidst the sea of options.`,
      },

      {
        header: "2. Harness the Power of Limited-Time Flash Sales",
        content: `Create a sense of urgency and excitement with limited-time flash sales. Whether it's a 24-hour discount bonanza or a weekend blowout, time-limited offers prompt quick decision-making. Emphasize the exclusivity of the deal and highlight the significant savings to drive immediate action.`,
      },
      {
        header: "3. Bundle Discounts for Added Value",
        content: `Entice customers by offering bundled deals that provide added value. Group related products together at a discounted rate compared to individual purchases. Clearly communicate the cost savings and emphasize the convenience of purchasing the bundle, encouraging customers to explore and buy more.`,
      },
      {
        header: "4. Encourage Bulk Purchases with Tiered Discounts",
        content: `Motivate customers to buy in larger quantities by implementing tiered discounts. Offer incremental discounts based on the quantity purchased, such as 10% off for one item, 15% off for two items, and 20% off for three or more. This strategy not only boosts sales but also encourages customers to explore your product range.`,
      },
      {
        header: "5. Loyalty Programs and Rewards",
        content: `Foster customer loyalty by introducing a rewards program. Provide points for every purchase, which customers can later redeem for discounts or free products. Loyalty programs not only incentivize repeat business but also cultivate a sense of belonging among shoppers.`,
      },
      {
        header: "6. Lure with Free Shipping Thresholds",
        content: `Tap into the allure of free shipping by setting a threshold based on a minimum purchase amount. Clearly communicate this threshold on your marketplace storefront, prompting customers to add more items to their carts to qualify for free shipping.`,
      },
      {
        header: "7. Exclusive Member Discounts for a Touch of Exclusivity",
        content: `Create a sense of exclusivity by offering special discounts to members or subscribers. Encourage shoppers to join your mailing list or loyalty program to access exclusive deals and promotions. This approach not only drives engagement but also establishes a direct relationship with your audience.`,
      },
      {
        header: "8. Seasonal and Holiday Promotions for Timely Excitement",
        content: `Align your offers with seasonal events, holidays, or special occasions to capitalize on heightened shopping activity. Whether it's a back-to-school sale, Black Friday promotion, or summer clearance, associating your deals with specific times of the year adds a sense of urgency and relevance.`,
      },

      {
        header: "9. Spark Engagement with Social Media Contests and Giveaways",
        content: `Leverage social media to run contests and giveaways. Encourage participants to share posts, tag friends, or follow your page for a chance to win exclusive discounts or free products. This not only expands your reach but also generates buzz and excitement around your brand.`,
      },
      {
        header: "10. Transparent Communication for Trust",
        content: `When promoting your offers, be transparent and clear in your communication. Clearly articulate the terms and conditions of the deals, including any limitations or restrictions. Transparent communication builds trust and ensures a positive shopping experience for your customers.`,
      },
      {
        header: "Conclusion",
        content: `Crafting irresistible offers for marketplace shoppers is an art that involves a deep understanding of your audience, strategic pricing, and effective promotion. By implementing these strategies, you can create deals that not only attract customers but also cultivate loyalty and repeat business. Let your offers shine as beacons of value and opportunity in the competitive realm of online marketplaces, making your storefront the go-to destination for savvy shoppers.`,
      },
    ],
    images: [
      {
        url: "https://img.freepik.com/premium-photo/sale-red-letters-confetti_262730-412.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      },
      // {
      //   url: "https://img.freepik.com/free-photo/woman-using-communication-internet-networking-concept_53876-26426.jpg?size=626&ext=jpg&ga=GA1.1.1880197662.1703582436&semt=ais",
      // },
    ],
  },

  {
    id: "5",
    header: `Decoding Success: A Guide to Measuring ROI on eCommerce Marketing Campaigns`,
    description: `In the dynamic and ever-evolving landscape of eCommerce, measuring the return on investment (ROI) for marketing campaigns is crucial for assessing performance and optimizing strategies. As businesses increasingly rely on digital channels to reach their audience, understanding how to effectively measure ROI becomes a key determinant of success. Let's delve into a comprehensive guide on how to measure ROI on eCommerce marketing campaigns.`,
    subContent: [
      {
        header:
          "1. Establish Clear Objectives and Key Performance Indicators (KPIs)",
        content: `Before diving into ROI measurement, define clear objectives for your eCommerce marketing campaigns. Whether it's increasing sales, driving website traffic, or boosting brand awareness, having specific and measurable goals is essential. Align these goals with key performance indicators (KPIs) such as conversion rates, click-through rates, and customer acquisition cost.`,
      },

      {
        header: "2. Track Conversions and Sales",
        content: `The primary indicator of ROI for eCommerce campaigns is tracking conversions and sales. Utilize analytics tools, such as Google Analytics or your eCommerce platform's built-in tracking features, to monitor the number of purchases directly attributed to your marketing efforts. This provides a tangible measure of the campaign's impact on actual revenue.`,
      },
      {
        header: "3. Attribution Modeling",
        content: `In the complex journey from awareness to conversion, customers may interact with multiple touchpoints before making a purchase. Employ attribution modeling to understand the contribution of each touchpoint in the customer journey. This helps allocate value to various channels and touchpoints, providing insights into the most effective elements of your marketing mix.`,
      },
      {
        header: "4. Customer Lifetime Value (CLV)",
        content: `Measuring the long-term impact of your eCommerce marketing campaigns involves calculating the Customer Lifetime Value (CLV). This metric assesses the total revenue a customer is expected to generate over their entire relationship with your brand. Understanding CLV provides a more comprehensive view of the ongoing impact of your marketing efforts.`,
      },
      {
        header: "5. Return on Ad Spend (ROAS)",
        content: `ROAS is a critical metric for eCommerce businesses running paid advertising campaigns. It is calculated by dividing the revenue generated from ads by the cost of the ads. A ROAS of 3:1, for example, indicates that for every $1 spent on advertising, $3 in revenue was generated. This metric is a direct indicator of the profitability of your paid marketing channels.`,
      },
      {
        header: "6. Customer Acquisition Cost (CAC)",
        content: `Calculate the cost of acquiring a new customer by dividing your total marketing expenses by the number of new customers acquired. Comparing CAC with the CLV helps ensure that the cost of acquiring a customer does not exceed the value they bring to your business over time.`,
      },
      {
        header: "7. Monitoring Website Analytics",
        content: `Leverage website analytics tools to gain insights into user behavior. Track metrics such as bounce rate, time spent on site, and pages per session to gauge the effectiveness of your marketing campaigns in engaging and retaining visitors. A well-optimized and engaging website contributes significantly to the success of your campaigns.`,
      },
      {
        header: "8. Social Media Metrics",
        content: `For eCommerce businesses active on social media platforms, measuring the impact of social campaigns is crucial. Track metrics like engagement rates, click-through rates, and conversion rates from social media channels. Social listening tools can also provide qualitative insights into customer sentiments and feedback.`,
      },

      {
        header: "9. Email Marketing Metrics",
        content: `For email campaigns, monitor metrics such as open rates, click-through rates, and conversion rates. Attribute sales directly tied to email campaigns and calculate the revenue generated per email sent. Email marketing platforms typically provide detailed analytics to assess the performance of your email campaigns.`,
      },
      {
        header: "10. A/B Testing and Iterative Improvement",
        content: `Implement A/B testing to compare different elements of your eCommerce marketing campaigns, such as ad creatives, landing page designs, or email subject lines. Analyze the performance of variations and iterate based on the insights gained. Continuous testing and optimization are essential for long-term success.`,
      },
      {
        header: "Conclusion",
        content: `Measuring ROI on eCommerce marketing campaigns is a multifaceted process that involves a combination of quantitative and qualitative metrics. By aligning your objectives with specific KPIs, tracking conversions, and leveraging various analytics tools, you can gain a comprehensive understanding of the effectiveness of your marketing efforts. Embrace a data-driven approach, iterate based on insights, and continuously refine your strategies to ensure ongoing success in the competitive eCommerce landscape.`,
      },
    ],
    images: [],
  },
];

export const FAQs = [
  {
    header: "Integration",
    content: [
      {
        title:
          "Do I need any integrations expertise to implement your product?",
        description:
          "a) No, you don't need integrations expertise to implement our product. We offer user-friendly integration processes to make implementation smooth for all users.",
      },
      {
        title: "Which all marketplaces are you integrated with?",
        description:
          "We are currently integrated with major marketplaces, including Amazon, Flipkart, Myntra, Meesho, etc.",
      },
      {
        title: "Which all marketing platform are you integrated with?",
        description:
          "Our product seamlessly integrates with popular marketing platforms such as Instagram, YouTube, Facebook, Pinterest, etc.",
      },
      {
        title:
          "Would you be able to integrate with a new marketplace or marketing platform of our choice?",
        description:
          "Yes, we can integrate with a new marketplace or marketing platform of your choice. Our flexible integration capabilities allow for customization.",
      },
      {
        title: "Are there any extra costs for Integrations?",
        description:
          "There are no extra costs for standard integrations. However, custom integrations or specific requirements may have associated costs, which will be discussed and agreed upon beforehand.",
      },
      {
        title:
          "My website is built on WooCommerce/Shopify/Ecwid/Shopdeck/Magento does it have an effect on the integrations efficacy?",
        description: `No, the efficacy of our integrations is not affected by the platform your website is built on. We provide compatibility with WooCommerce, Shopify, Ecwid, Shopdeck, Magento, and various other platforms.`,
      },
      {
        title: `Which all CMS platforms does your product support?`,
        description: `Our product supports a range of CMS platforms, including but not limited to WordPress, Drupal, Joomla, and more.`,
      },

      {
        title: `Which all other tools are you integrated with?`,
        description: `Apart from marketplaces and marketing platforms, our product integrates with various tools such as [List of Integrated Tools].`,
      },

      {
        title: `Can you integrate with my shipping partner and process the orders?`,
        description: ` Yes, we can integrate with your shipping partner and efficiently process orders, providing a streamlined experience for your shipping operations.`,
      },
    ],
  },

  {
    header: "Data Security",
    content: [
      {
        title: "Is my and my customers data safe?",
        description: `Yes, both your and your customers' data are treated with the utmost security. We employ robust security measures to safeguard sensitive information.`,
      },

      {
        title: "Can I host your product on my servers?",
        description: `Currently, our product is hosted on our servers, but we can also offer the option for on-premise hosting to meet specific client requirements which needs to be discussed on case basis.`,
      },
      {
        title: "Where is your product hosted?",
        description: `Our product is hosted on secure AWS servers located to ensure reliability and compliance with global and Indian data protection standards.`,
      },

      {
        title: `How do you protect Client data?`,
        description: ` Client data is protected through encryption, access controls, regular security audits, and adherence to industry best practices. We prioritize the confidentiality and integrity of the data.`,
      },
      {
        title: " Is your product GDPR complaint?",
        description: `Yes, our product is GDPR compliant. We take data protection laws seriously and implement measures to ensure compliance with various privacy regulations.`,
      },
      {
        title: `Would you be selling my consumers’ data to any third party?`,
        description: `No, we do not sell your consumers' data to any third party. We adhere to strict privacy policies to maintain the confidentiality of user information.`,
      },

      {
        title: `Would you be cross selling offers and discounts of other brands to my customers`,
        description: `No, we do not engage in cross-selling offers and discounts of other brands to your customers. We respect your brand integrity and prioritize a focused and personalized customer experience.`,
      },

      {
        title: `Can other merchants see my data?`,
        description: `No, other merchants cannot access your data. Our system is designed to ensure data segregation, providing each merchant with secure and private access to their own information.`,
      },

      {
        title: `Can you integrate with my shipping partner and process the orders?`,
        description: ` Yes, we can integrate with your shipping partner and efficiently process orders, providing a streamlined experience for your shipping operations.`,
      },
    ],
  },

  {
    header: "Analytics",
    content: [
      {
        title: `What kind of reports does your tool provide?`,
        description: `Our tool provides comprehensive reports covering various aspects such as sales performance segmented across all possible parameters, campaign performance, customer behavior, inventory management, and more. You can access detailed insights to make informed decisions.`,
      },
      {
        title: "Is the data and insights updated on a real time basis?",
        description: ` Yes, the data and insights are updated in real-time. This ensures that you have access to the most current information, allowing for timely and informed decision-making.`,
      },
      {
        title: `Would you be able to customise the insights and reporting?`,
        description: `Yes, we offer the flexibility to customize insights and reporting based on your specific needs. You can tailor the reports to focus on key metrics that matter most to your business.`,
      },
      {
        title: `Can I access the analytics panel on my mobile ?`,
        description: ` Yes, our analytics panel is designed to be accessible on mobile devices. You can conveniently access and review your analytics on-the-go, making it easier to stay informed about your business performance from anywhere.`,
      },
    ],
  },

  {
    header: "Payments & Billing",
    content: [
      {
        title: ` What are the charges for your services?`,
        description: `Our service charges depend on the specific plan and features you choose. We offer transparent pricing, and you can find detailed information on pricing page or contact our sales team for a customized quote.`,
      },
      {
        title: " Do you charge basis per order?",
        description: `While we may have different pricing plans, we typically do not charge on a per-order basis. Our pricing structure is designed to accommodate various business needs and scales.`,
      },
      {
        title: `I have multiple brands, Would I need a separate account for each?`,
        description: `Depending on your business structure and requirements, you may want to keep the analytics and tracking of different brands separate. We can discuss your specific needs and tailor a solution that works best for you.`,
      },
      {
        title: ` Would you provide a GST invoice for your services?`,
        description: `Yes, we provide GST invoices for our services. Our billing process is transparent, and you will receive detailed invoices that comply with relevant tax regulations.`,
      },
    ],
  },

  {
    header: "General",
    content: [
      {
        title: `What is Aarith? How does it help my business?`,
        description: `Aarith is a robust business analytics tool specifically designed to empower retail D2C (Direct-to-Consumer) brands in effectively managing their marketing and sales activities. It provides comprehensive insights into various aspects of your business, including sales performance, customer behavior, and inventory management.
        Our tool assists your business by streamlining operations and increasing efficiency. With Aarith, you can make informed decisions based on real-time data, optimize marketing strategies, and enhance overall sales performance for your retail D2C brand.`,
      },
      {
        title: "How much time would you take for the whole setup?",
        description: `The setup time varies based on the complexity of your requirements. Typically, for a straight integration, it can take upto 2-3 days to go live. We strive to make the process as efficient as possible.`,
      },
      {
        title: ` What kind of support would you be providing after the sales?`,
        description: `We provide ongoing support after the sale, including assistance with any issues, updates, and additional training if needed. Our support team is dedicated to ensuring the smooth operation of our services for your business.

        For any support required pertaining to the insights and analysis, setting of dashboards, etc. you can connect with our Solutions Team.`,
      },
      {
        title: `Does your team work on Saturdays and Sundays?`,
        description: `Our support team operates during regular business hours 9 am - 7 pm, Monday through Friday. However, emergency support may be available on weekends.`,
      },

      {
        title: `Do you cater to any specific category of brands or are you brand/category agnostic?`,
        description: `We cater to a diverse range of brands and industries. Our solutions are designed to be adaptable, making us brand/category agnostic to serve a broad spectrum of businesses.`,
      },
      {
        title:
          "Do we have to sign agreements every year while renewing the subscriptions?",
        description: `Our subscription agreements typically have a fixed term, and renewal terms can be discussed. Yes, for every renewal there are fresh agreements being signed up. We aim to provide flexible arrangements that meet your business needs.`,
      },
      {
        title: `Do you provide other custom development services?`,
        description: `Yes, we offer custom development services to meet specific requirements beyond our standard offerings. Our team can discuss and tailor solutions according to your business needs.`,
      },
      {
        title: `What happens if your service is down?`,
        description: `We have robust systems in place to minimize downtime. In the rare event of a service disruption, we prioritize quick resolution and keep clients informed about the status and progress of the resolution process.`,
      },
    ],
  },
];
