import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToken,
} from "@chakra-ui/react";
import { Purple } from "utils/constants";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  Link,
  MapPin,
  Phone,
} from "phosphor-react";

import "./Footer.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { useNavigate } from "react-router-dom";
import { LinkedIn } from "@mui/icons-material";

import LogoWhite from "assets/images/brand-logo/Aarith-Logo-White.png";

function Footer() {
  // return (
  //   <Box
  //     //  bg="white"
  //     bg={Purple}
  //     width="100%"
  //   >
  //     <Container
  //       centerContent
  //       maxW={{ base: "container.sm", xl: "container.xl" }}
  //       paddingTop={{ base: "3rem", md: "6.5rem" }}
  //       // py={{ base: "3rem", md: "6.5rem" }}
  //     >
  //       <Heading
  //         as="h1"
  //         size="h1"
  //         color={"white"}
  //         // color="black"
  //         // pb={{ base: "3rem", md: "6.5rem" }}
  //         textAlign={{ base: "start", md: "center" }}
  //       >
  //         Looks interesting to explore?
  //       </Heading>
  //       <Text color={"white"}>
  //         Aarith is an AI-driven, smart platform providing business and customer
  //         insights at fingertips.
  //       </Text>
  //       <br></br>
  //       <Button
  //         //  colorScheme="yellow"
  //         colorScheme="orange"
  //         color="black"
  //       >
  //         Talk to us
  //       </Button>
  //       <br></br>
  //       <br></br>
  //       <Divider borderColor="gray.400" />
  //       <Container
  //         maxW="container.xl"
  //         color={"white"}
  //         py="1.25rem"
  //         textAlign="center"
  //       >
  //         ©{`${new Date().getFullYear()}`} Aarith, All rights reserved.
  //       </Container>
  //       {/* <i class="fa-brands fa-whatsapp"></i> */}
  //     </Container>
  //   </Box>
  // );
  // const [blue400] = useToken("colors", ["blue.400"]);

  // return (
  //   <Box bg={Purple} id="contact_us">
  //     <Container maxW={{ base: "container.sm", xl: "container.xl" }} py="5rem">
  //       <Stack
  //         direction={{ base: "column", xl: "row" }}
  //         alignItems="start"
  //         spacing="3.75rem"
  //       >
  //         <Stack direction={{ base: "column", sm: "row" }} spacing="3.75rem">
  //           {/* Logo & Links */}
  //           <Stack direction="column" spacing="1.5rem">
  //             <Box py="0.25rem">
  //               {/* <img src={LogoBlack} alt="Logo" width="128" height="128" /> */}
  //               <Heading color={"white"} as={"h4"} size="lg">
  //                 Aarith
  //               </Heading>
  //             </Box>
  //             <Text color={"white"}>
  //               Track revenue and promotions across all channels and infer
  //               granular level actionable business, customer and competitor
  //               insights.
  //             </Text>
  //             <Stack direction="row" spacing="1rem">
  //               <Link href="https://instagram.com/">
  //                 <InstagramLogo size={20} />
  //               </Link>
  //               <Link href="https://facebook.com/">
  //                 <FacebookLogo size={20} weight="fill" />
  //               </Link>
  //             </Stack>
  //           </Stack>
  //           {/* Contacts */}
  //           <Stack direction="column" spacing="1.5rem">
  //             <Heading as="h3" size="h3">
  //               Contact info
  //             </Heading>
  //             <Stack direction="row" spacing="0.5rem">
  //               <MapPin size={24} color={blue400} />
  //               <Text>2715 Ash Dr. San Jose, South Dakota 83475</Text>
  //             </Stack>
  //             <Stack direction="row" spacing="0.5rem">
  //               <Phone size={24} color={blue400} />
  //               <Text>(219) 555-0114</Text>
  //             </Stack>
  //             <Stack direction="row" spacing="0.5rem">
  //               <EnvelopeSimple size={24} color={blue400} />
  //               <Text>info@aarith.com</Text>
  //             </Stack>
  //             <Stack direction="row" spacing="0.5rem">
  //               <EnvelopeSimple size={24} color={blue400} />
  //               <Text>care@aarith.com</Text>
  //             </Stack>
  //           </Stack>
  //         </Stack>
  //         {/* Email form */}
  //         <Stack
  //           direction="column"
  //           spacing="1.5rem"
  //           w="100%"
  //           maxW={{ base: "auto", xl: "517px" }}
  //         >
  //           <Heading as="h3" size="h3">
  //             Get in touch
  //           </Heading>
  //           <Input placeholder="Your name" bg="white" />
  //           <Input placeholder="Your email" bg="white" />
  //           <Textarea placeholder="Your message" bg="white" resize="none" />
  //           <Box>
  //             <Button colorScheme="blue">Send</Button>
  //           </Box>
  //         </Stack>
  //       </Stack>
  //     </Container>
  //     <Divider borderColor="gray.400" />
  //     <Container
  //       maxW="container.xl"
  //       py="1.25rem"
  //       color={"white"}
  //       textAlign="center"
  //     >
  //       ©2024 Aarith, All rights reserved.
  //     </Container>
  //   </Box>
  // );

  const navigate = useNavigate();

  const helpChangeHandler = (routeUrl) => {
    navigate(`/${routeUrl}`);
  };

  function Feature({ title, desc, ...rest }) {
    return (
      <Box height="200px" p={5} border={"none"} {...rest}>
        <Heading fontSize="xl">{title}</Heading>
        <Text mt={4}>{desc}</Text>
      </Box>
    );
  }

  return (
    <div style={{backgroundColor:`${Purple}`}}>
      <Stack
      mx={'5%'}
        spacing={150}
        direction="row"
        color={"white"}
        // backgroundColor={Purple}
      >
        <Box 
        // ml={"3%"} 
        height="220px" width={"65%"} p={5} border={"none"}>
          <div>
            <img src={LogoWhite} alt="Logo" width="150" height="130" />
          </div>
          <Text ml={4}>
            {`Empowering ecommerce businesses with advanced analytics for informed decision-making.`}
          </Text>
        </Box>
        <Box 
        mt={"40%"}  
        // height="200px"
         p={5} border={"none"}> 
          <ul >
        <li
              style={{
                marginBottom: "2%",
                listStyleType:'none'
              }}
            >
              <Button
                onClick={() => {
                  helpChangeHandler("support");
                }}
                color={"white"}
                variant="link"
              >
                Support
              </Button>
            </li>
            <li style={{listStyleType:'none'}}>
              <Button
                onClick={() => {
                  helpChangeHandler("careers");
                }}
                variant="link"
                color={"white"}
              >
                Careers
              </Button>
            </li></ul>
            </Box>
        <Box mt={"19%"} height="200px" p={5} border={"none"}>
          <ul className="fotter__help__links">
            {/* <li
              style={{
                marginBottom: "2%",
              }}
            >
              <Button
                onClick={() => {
                  helpChangeHandler("careers");
                }}
                color={"white"}
                variant="link"
              >
                Support
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  helpChangeHandler("customer care");
                }}
                variant="link"
                color={"white"}
              >
                Careers
              </Button>
            </li> */}
            <li
              style={{
                marginBottom: "2%",
              }}
            >
              <Button
                onClick={() => {
                  helpChangeHandler("privacyPolicy");
                }}
                color={"white"}
                variant="link"
              >
                Privacy Policy
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  helpChangeHandler("tC");
                }}
                variant="link"
                color={"white"}
              >
                Terms and Conditions
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  helpChangeHandler("eula");
                }}
                variant="link"
                color={"white"}
              >
                EULA
              </Button>
            </li>
          </ul>
          <ul
            style={{
              height: "70%",
              width: "100%",
              color: "white",
              listStyleType: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <li>
              <LinkedIn style={{cursor:'pointer'}} onClick={() => console.log(`linkedin click`)} />
            </li>
            <li style={{ marginLeft: "5%" }}>
              <InstagramIcon style={{cursor:'pointer'}} />
            </li>
            <li style={{ marginLeft: "5%" }}>
              <YouTubeIcon style={{cursor:'pointer'}} />
            </li>
          </ul>
        </Box>
      </Stack>
      <Divider borderColor="gray.400" />
      <div className="fotter__copyright__container">
        <ul className="nav">
          <li className="footer__copyright">
            {`©${new Date().getFullYear()} Aarith. All Rights Reserved`}
          </li>
        </ul>
      </div>
    </div>

  );
}

export default Footer;
