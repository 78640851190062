import React, { useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
} from "@chakra-ui/react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// import LogoWhite from "assets/images/brand-logo/Aarith-Logo-White.png";
import LogoWhite from "assets/images/brand-logo/Aarith-Logo-Colour.png";
import MobileNav from "./MobileNav";
import { Purple } from "utils/constants";
// import NavMenu from "components/NavMenu";
import "components/NavMenu/navmenu.css";

function NavBar() {
  const navigate = useNavigate();
  const [navLinks] = useState([
    {name:"Modules",path:""},
    {
      name: "Pricing",
      path: "/pricing",
    },
    {name:'Resources',path:''},
    {name:'Legal',path:""},
    // {
    //   name: "Blog",
    //   path: "/blog",
    // },
    {
      name: "Contact Us",
      path: "/contact_us",
    },
    // {
    //   name: "Modules",
    //   path: "/features",
    //   subMenus: [
    //     { id: "0", label: "Insights" },
    //     { id: "1", label: "Management" },
    //     { id: "2", label: "Engagement" },
    //     { id: "3", label: "Forecasting" },
    //   ],
    // },
  ]);

  // const [displaySubMenus, setDisplaySubMenus] = useState(false);

  // const onHoverMenus = (subMenus) => {
  //   if (subMenus && subMenus.length > 0) {
  //     // alert(subMenus);
  //     setDisplaySubMenus(true);
  //     return;
  //   }
  //   setDisplaySubMenus(false);
  // };

  // const onMenuHoverOver = () => {
  //   setDisplaySubMenus(false);
  // };

  const navbarView = (link) => {
    if('Legal' === link.name) {
      return (<div class="dropdown">
        <Button
          onClick={() => {
            navigate("/privacyPolicy");
          }}
          id="dropdownMenuButton"
          data-mdb-toggle="dropdown"
          variant="link"
          colorScheme="black"
          color="black"
        >
          Legal
        </Button>
        <ul
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <li>
          <a
            class="dropdown-item"
            onClick={() => {
              navigate("/privacyPolicy");
            }}
          >
            Privacy Policy
          </a>
        </li>
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/tC");
              }}
            >
              Terms and Conditions
            </a>
          </li>
          <li>
          <a
            class="dropdown-item"
            onClick={() => {
              navigate("/eula");
            }}
          >
            EULA
          </a>
        </li>
        
        </ul>
      </div>)
    }
    if('Modules' === link.name) {
      return (<div class="dropdown">
        <Button
          onClick={() => {
            // alert("sfd");
            navigate("/features");
          }}
          // class="dropdown-toggle"
          id="dropdownMenuButton"
          data-mdb-toggle="dropdown"
          variant="link"
          // fontWeight={"bold"}
          // onMouseEnter={() => onHoverMenus(link?.subMenus)}
          // onMouseLeave={() => onMenuHoverOver()}
          // colorScheme="whiteAlpha"
          colorScheme="black"
          // color="white"
          color="black"
          // rightIcon={<KeyboardArrowDownIcon />}
        >
          Modules
        </Button>
        <ul
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/insights");
              }}
            >
              Insights
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/management");
              }}
            >
              Management
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/engagement");
              }}
            >
              Engagement
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/forecasting");
              }}
            >
              Forecasting
            </a>
          </li>
        </ul>
      </div>)
    }
   if('Resources' === link.name) {
    return (<div class="dropdown">
      <Button
        onClick={() => {
          navigate("/faqs");
        }}
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        variant="link"
        colorScheme="black"
        color="black"
      >
        Resources
      </Button>
      <ul
        class="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
       
        <li>
          <a
            class="dropdown-item"
            onClick={() => {
              navigate("/faqs");
            }}
          >
            FAQs
          </a>
        </li>
        <li>
            <a
              class="dropdown-item"
              onClick={() => {
                navigate("/blog");
              }}
            >
              Blog
            </a>
          </li>
      </ul>
    </div>)
   }

   return (<Button
    key={`nav${link.path}`}
    as={NavLink}
    to={link.path}
    variant="link"
    colorScheme="black"
    color="black"
  >
    {link.name}
  </Button>)
  }

  return (
    // <Box bg="gray.900">
    <Box
      bg="white"
      __css={{
        position: "-webkit - sticky",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      <Container maxW="container.xl" py="1.5rem">
        <HStack justifyContent="space-between" spacing="2rem">
          {/* Left links */}
          <HStack spacing="4rem">
            <Link as={NavLink} to="/">
              <img src={LogoWhite} alt="Logo" width="150" height="120" />
            </Link>
            <Show above="lg">
              <HStack spacing="5.25rem">
                {/* <div class="dropdown">
                  <Button
                    onClick={() => {
                      // alert("sfd");
                      navigate("/features");
                    }}
                    // class="dropdown-toggle"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    variant="link"
                    // fontWeight={"bold"}
                    // onMouseEnter={() => onHoverMenus(link?.subMenus)}
                    // onMouseLeave={() => onMenuHoverOver()}
                    // colorScheme="whiteAlpha"
                    colorScheme="black"
                    // color="white"
                    color="black"
                    // rightIcon={<KeyboardArrowDownIcon />}
                  >
                    Modules
                  </Button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/insights");
                        }}
                      >
                        Insights
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/management");
                        }}
                      >
                        Management
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/engagement");
                        }}
                      >
                        Engagement
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/forecasting");
                        }}
                      >
                        Forecasting
                      </a>
                    </li>
                  </ul>
                </div> */}

                {navLinks.map((link) => {
                  return (navbarView(link));
                  // return (
                  //   <Button
                  //     key={`nav${link.path}`}
                  //     as={NavLink}
                  //     to={link.path}
                  //     variant="link"
                  //     colorScheme="black"
                  //     color="black"
                  //   >
                  //     {link.name}
                  //   </Button>
                  // );
                })}

                {/* <div class="dropdown">
                  <Button
                    onClick={() => {
                      navigate("/features");
                    }}
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    variant="link"
                    colorScheme="black"
                    color="black"
                  >
                    Resources
                  </Button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/viewFeature/0");
                        }}
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/viewFeature/1");
                        }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          navigate("/faqs");
                        }}
                      >
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div> */}

              </HStack>
            </Show>
          </HStack>
          <Show above="lg">
            <HStack spacing="1.125rem">
              <Button
                as={NavLink}
                to="/login"
                variant="ghost"
                colorScheme="whiteAlpha"
                color="white"
              >
                Log In
              </Button>
              <Button
                as={NavLink}
                to="/signup"
                color={"white"}
                //  colorScheme="purple"
                backgroundColor={Purple}
                // colorScheme={Purple}
              >
                Sign Up
              </Button>
            </HStack>
          </Show>
          {/* Mobile menu */}
          <MobileNav links={navLinks} />
        </HStack>
      </Container>
    </Box>
  );
}

export default NavBar;
