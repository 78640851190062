const Textarea = {
  sizes: {
    md: {
      px: "1.125rem",
      py: "0.875rem",
      borderRadius: "0.875rem",
    },
  },
};

export default Textarea;
