import React from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "components/Footer";
import NavBar from "components/NavBar";

import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Pricing from "pages/Pricing";
import ContactUs from "pages/ContactUs";
import Blog from "pages/Blog";
import Features from "pages/Features";
// import ViewFeature from "pages/ViewFeature";
import ViewBlog from "pages/ViewBlog";
import Insights from "pages/Insights";
import Management from "pages/Management";
import Engagement from "pages/Engagement";
import Forecasting from "pages/Forecasting";
import FAQ from "pages/FAQ";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Eula from "pages/Eula";
import TermsAndConditions from "pages/T&C";
import InnerPage from "pages/Smartform";

export default function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/viewBlog/:index" element={<ViewBlog />} />
        <Route path="/features" element={<Features />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/management" element={<Management />} />
        <Route path="/engagement" element={<Engagement />} />
        <Route path="/forecasting" element={<Forecasting />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/tC" element={<TermsAndConditions />} />
        <Route path="/eula" element={<Eula />} />
        <Route path="/smartforms" element={<InnerPage />} />
        {/* <Route path="/viewFeature/:index" element={<ViewFeature />} /> */}
      </Routes>
      <Footer />
    </>
  );
}
