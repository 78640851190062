import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Orange, Purple } from 'utils/constants';

const InnerPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

    const styles = {
        container: {
          maxWidth: '800px',
          margin: 'auto',
          padding: '20px',
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          fontFamily: 'Arial, sans-serif',
        },
        content: {
          marginBottom: '20px',
        },
        heading: {
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333',
          marginBottom: '10px',
          textAlign: 'center',
        },
        paragraph: {
          fontSize: '16px',
          color: '#666',
          lineHeight: '1.6',
          textAlign: 'center',
        },
        actions: {
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        },
        button: {
          backgroundColor: '#2196f3',
          color: '#fff',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          fontSize: '16px',
          cursor: 'pointer',
          marginLeft: '10px',
          outline: 'none',
          transition: 'background-color 0.3s',
        },
        features: {
          marginBottom: '20px',
        },
        feature: {
          marginBottom: '20px',
        },
        featureHeading: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#333',
          marginBottom: '10px',
        },
        featureText: {
          fontSize: '16px',
          color: '#666',
          lineHeight: '1.6',
        },
        cta: {
          textAlign: 'center',
        },
        ctaText: {
          fontSize: '18px',
          color: '#444',
          marginBottom: '10px',
        },
      };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h2 style={styles.heading}>Drive Sales and Conversion on Instagram Effortlessly</h2>
        <p style={styles.paragraph}>
          Designed for Instagram sellers who value efficiency and customer satisfaction. Aarith smart forms are crafted to streamline your sales process, offering more conversions and better operational efficiency.
        </p>
        <div style={styles.actions}>
          {/* <button style={styles.button}>Sign Up</button> */}
          <Button
                // as={NavLink}
                _hover={{
                  backgroundColor:'#301934'
                }}
                to="/signup"
                color={"white"}
                //  colorScheme="purple"
                backgroundColor={Purple}
                // colorScheme={Purple}
              >
                Sign Up
              </Button>
          {/* <button style={{ ...styles.button, backgroundColor: '#ffaa00' }}>Book a Demo</button> */}
          <Button
                // as={NavLink}
                mx={'5px'}
                _hover={{
                  backgroundColor:'#f58802'
                }}
                to="/signup"
                color={"white"}
                //  colorScheme="purple"
                backgroundColor={Orange}
                // colorScheme={Purple}
              >
                Book a Demo
              </Button>
        </div>
      </div>
      <div style={styles.features}>
        <div style={styles.feature}>
          <h3 style={styles.featureHeading}>Multiple Payment Options</h3>
          <p style={styles.featureText}>
            Empower your customers with the freedom to pay their way. Our smart forms support a variety of payment methods, ensuring a smooth transaction experience. From credit cards to popular digital wallets, your buyers can choose the option that suits them best, directly through Instagram without the need of a website.
          </p>
        </div>
        <div style={styles.feature}>
          <h3 style={styles.featureHeading}>Buyer Consolidation Made Easy</h3>
          <p style={styles.featureText}>
            Say goodbye to scattered DMs and confusing threads. With our smart forms, you can consolidate buyer inquiries and orders seamlessly. Whether you're managing a few customers or scaling your business rapidly, our intuitive platform keeps everything organized in one place.
          </p>
        </div>
        <div style={styles.feature}>
          <h3 style={styles.featureHeading}>One-Click Checkout Experience</h3>
          <p style={styles.featureText}>
            Transform browsing into buying with our streamlined checkout process. Our one-click checkout feature eliminates friction, allowing customers to complete their purchases swiftly and securely without leaving Instagram. It’s convenience at its finest.
          </p>
        </div>
      </div>
      <div style={styles.cta}>
        <p style={styles.ctaText}>
          Ready to elevate your Instagram sales? Explore the possibilities with our smart forms today.
        </p>
        <Button
                // as={NavLink}
                to="/signup"
                color={"white"}
                //  colorScheme="purple"
                backgroundColor='#4caf50'
                _hover={{
                  backgroundColor:'#417042'
                }}
                // colorScheme={Purple}
              >
                Book a Demo
              </Button>
        {/* <button style={{ ...styles.button, backgroundColor: '#4caf50' }}>Get Started</button> */}
      </div>
    </div>
  );
};



export default InnerPage;
