import React from "react";
import { CardGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Orange, Purple } from "utils/constants";

const defaultCardImage = "assets/images/features/feature-image-2.png";

const CardView = ({ data, onButtonClick }) => {
  return (
    <div
      //   className="d-flex justify-content-around"
      style={{
        display: "grid",
        marginRight:'5%',
        marginLeft:'5%',
        marginTop:'2%',
        marginBottom:'2%',
        // backgroundColor: `${Orange}`,
        gridTemplateColumns: "repeat(3,1fr)",
      }}
    >
      {data.map((item, index) => (
        <CardGroup>
          <Card
            key={index}
            style={{
              width: "25rem",
              margin: "3%",
              border: `1px solid ${Orange}`,
            }}
          >
            <Card.Img
              variant="top"
              src={
                item.images.length > 0 ? item.images[0]?.url : defaultCardImage
              }
            />
            {/* {console.log(item.description.substr(0, 20))} */}
            <Card.Body>
              <Card.Title>{item?.header}</Card.Title>
              <Card.Text>{`${item?.description.substr(
                0,
                80,
              )}  ....`}</Card.Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: Orange,
                    border: "none",
                  }}
                  // variant="primary"
                  onClick={() => {
                    onButtonClick(item);
                  }}
                >
                  Read More
                </Button>
              </div>
            </Card.Body>
          </Card>
        </CardGroup>
      ))}
    </div>
  );
};

export default CardView;
