import React, { useEffect } from "react";

import FeaturesBig from "components/FeatureImages";
import FeaturesGrid from "components/FeatureGrid";
import Gallery from "components/Gallery";
import Hero from "components/Hero";
import LogoCloud from "components/LogoCloud";
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  Text,
  Textarea,
  useToken,
} from "@chakra-ui/react";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  LinkSimple,
  MapPin,
  Phone,
} from "phosphor-react";

// import LogoBlack from "assets/images/brand-logo/Aarith-Logo-Colour.png";
// import Footer from "components/Footer";
import { Orange, Purple } from "utils/constants";

function ContactUs() {
  // const [blue400] = useToken("colors", ["blue.400"]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const contactUsHandler = () => {};

  return (
    <Box bg="white" id="contact_us">
      <div style={{backgroundColor:`${Purple}`}}>
        <Heading
        // my={'2%'}
        pt={'1%'}
          textAlign={"center"}
          color={'White'}
        >
          Lets start a conversation
        </Heading>
        <br></br>
        <Text
          textAlign={"center"}
          mx={'20%'}
          pb={'1%'}
          // mr={"20%"}
          // ml={"20%"}
          fontWeight={"bold"}
          fontSize={"lg"}
          color={"white"}
        >
          Whether you have a question, need support, or just want to say hello,
          we'd love to hear from you.
        </Text>
        </div>
      {/* <Container maxW={{ base: "container.sm", xl: "container.xl" }} py="5rem"> */}
      <Container maxW={{ base: "container.sm", xl: "container.xl" }} py='2rem'>
        
        {/* <Heading
          textAlign={"center"}
          color={Purple}
        >
          Lets start a conversation
        </Heading>
        <br></br>
        <Text
          textAlign={"center"}
          mr={"20%"}
          ml={"20%"}
          fontWeight={"bold"}
          fontSize={"lg"}
          color={"black"}
        >
          Whether you have a question, need support, or just want to say hello,
          we'd love to hear from you.
        </Text> */}
        <br></br>
        <br></br>
        <Stack
          direction={{ base: "column", xl: "row" }}
          alignItems="start"
          spacing="3.75rem"
        >
          <Stack direction={{ base: "column", sm: "row" }} spacing="3.75rem">
            Logo & Links
            <Stack
              direction="column"
            >

              <Heading as="h3" size="h3">
                Office Address{" "}
              </Heading>
              <Text>
                AG03, Royce, Kodigehalli, Krishnarajapuram, Bengaluru, 560036
                {/* Company Name: Imnemosyne Technologies Private Limited */}
              </Text>
              <br></br>
              <Heading as="h3" size="h3">
                Phone{" "}
              </Heading>
              <Text>+91-8657952260</Text>
              <br></br>
              <Heading as="h3" size="h3">
                Email
              </Heading>
              <Text>info@aarith.com</Text>
              <br></br>
              <Stack direction="row" spacing="1rem">
                <Link href="https://instagram.com/">
                  <InstagramLogo size={20} />
                </Link>
                <Link href="https://facebook.com/">
                  <FacebookLogo size={20} weight="fill" />
                </Link>
              </Stack>
            </Stack>
            Contacts
          </Stack>
          Email form
          <Stack
            direction="column"
            spacing="1.5rem"
            w="100%"
            maxW={{ base: "auto", xl: "517px" }}
          >
            <Input placeholder="Name" bg="white" />
            <Input placeholder="Phone number" bg="white" />
            <Input placeholder="Email" bg="white" />
            <Select placeholder="Need?" bg="white" resize="none">
              <option value="book a demo">Book a Demo</option>
              <option value="sign-up for aarith">Sign-up for Aarith</option>
              <option value="know the price">Know the Price</option>
              <option value="career opportunity"> Career Opportunity</option>
            </Select>
            <Box alignSelf={"center"}>
              <Button onClick={contactUsHandler} backgroundColor={Orange}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default ContactUs;
