import React, { useState, useEffect } from "react";

import {
  Flex,
  Heading,
  VStack,
  Stack,
  Box,
  Grid,
  Text,
  Button,
  Container,
} from "@chakra-ui/react";
import PricingBox from "components/PricingBox";
import Footer from "components/Footer";
import { FAQs, Grey, Orange, Purple } from "utils/constants";
import Accordian from "components/Accordian";

const infoMessage = "Standard Taxes Apply";

const businessPrices = [
  {
    name: "Basic",
    price: "25,000",
    subHeader: "Startups & Independent Retailers",
    features: ["Product Performance", "Whatsapp Integration", "Catalouguing"],
    // features: [
    //   "Startups & Independent",
    //   "Single channel presence",
    //   "< 1 Cr / month",
    // ],
    info: infoMessage,
  },
  {
    name: "Standard",

    price: "50,000",
    subHeader: "Small Businesses",
    features: [
      "Product Performance",
      "Campaign Analysis",
      "Whatsapp Integration",
      "Catalouguing",
    ],
    popular: true,
    // features: [
    //   "Small Businesses",
    //   "2-3 Business Verticals",
    //   "upto 10cr / monthly",
    // ],
    info: infoMessage,
  },
  {
    name: "Premium",
    price: "75,000",
    features: [
      "Product Performace",
      "Campaign Analysis",
      "Whatsapp Integration",
      "Competitive Insights",
      "Catalouguing",
      "Social Media Tracking",
    ],
    subHeader: "Enterprise",
    // features: [
    //   "Enterprise",
    //   "Multi-brand Omni-channel presence",
    //   "10 cr+ Monthly",
    // ],
    info: infoMessage,
  },
];

const Pricing = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        minH="100vh"
        w="full"
      >
        <Stack
          spacing={5}
          marginY={5}
          justifyContent="flex-start"
          alignItems="center"
          maxWidth="1200px"
          w="full"
          paddingX={[5, 0]}
        >
          <VStack alignItems="center" w="full">
            <Heading color={Purple}>Affordable Excellence</Heading>
            <Text mb={5} textAlign="center">
              Compare and choose what fits your requirements and get started
              today
            </Text>
          </VStack>
          <Stack
            spacing={0}
            isInline
            border="1px solid"
            borderColor="teal.300"
            borderRadius="4px"
            justifyContent="center"
            alignItems="stretch"
            display="flex"
            width="fit-content"
            backgroundColor="white"
            mb={3}
          >
            {/* <Box
            _hover={{
              cursor: "pointer",
            }}
            backgroundColor="teal.300"
            color="white"
            p=".3rem 1rem"
            // onClick={() => {
            //   setTab("businessPrice");
            // }}
          >
            Business
          </Box> */}
            {/* <Box
            _hover={{
              cursor: "pointer",
            }}
            backgroundColor="teal.500"
            color="white"
            p=".3rem 1rem"
            onClick={() => {
              setTab("modulePrice");
            }}
          >
            Module
          </Box> */}
          </Stack>
          <Grid
            w="full"
            gap={5}
            justifyContent="center"
            templateColumns={{
              base: "inherit",
              md: "repeat( auto-fit, 250px )",
            }}
          >
            {businessPrices.map((price) => (
              <PricingBox key={price.name} {...price} />
            ))}
            {/* {getPriceView()} */}
          </Grid>
          {/* <Button>Talk to Us</Button> */}
        </Stack>
        <Container
          // mt={"10px"}
          // mb={"1px"}
          centerContent
          backgroundColor={Grey}
          // backgroundColor="orange.200"
          maxW="100%"
          // color={Orange}
        >
          {" "}
          <Heading mt={'15px'}>Need Customization?</Heading>
          <Text pt={"5px"} pb={"5px"}>
            If you have specific requirements or need a custom plan tailored to
            your business, contact us. We're here to help.
          </Text>
          <Button color={'white'} my={'15px'} backgroundColor={Purple}>
            Talk to Us
          </Button>
        </Container>
      </Flex>
      {/* <Container marginTop={"2%"} marginBottom={"1%"}>
        <Heading as={"h6"} size="xl">
          Frequently Asked Questions
        </Heading>
        <br></br>
      </Container>
      {FAQs.map((item, index) => (
        <>
          <Heading
            pb={"1%"}
            pt={"1%"}
            style={{ textAlign: "center" }}
            as={"h6"}
            size="md"
          >
            {" "}
            {item.header}
          </Heading>
          <Accordian data={item.content} />
        </>
      ))} */}
    </>
  );
};

export default Pricing;
