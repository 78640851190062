import React, { useEffect } from "react";
import { Container, Heading } from "@chakra-ui/react";
import { FAQs } from "utils/constants";
import Accordian from "components/Accordian";

const FAQ = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Container marginTop={"2%"} marginBottom={"1%"}>
        <Heading as={"h6"} size="xl">
          Frequently Asked Questions
        </Heading>
        <br></br>
      </Container>
      {FAQs.map((item, index) => (
        <>
          <Heading
            pb={"1%"}
            pt={"1%"}
            style={{ textAlign: "center" }}
            as={"h6"}
            size="md"
          >
            {" "}
            {item.header}
          </Heading>
          <Accordian data={item.content} />
        </>
      ))}
    </>
  );
};

export default FAQ;
