import React from "react";

import {
  AspectRatio,
  Box,
  Center,
  Container,
  Heading,
  Image,
  Spacer,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import partnerLogos from "assets/json/partner-logos.json";
import { Purple } from "utils/constants";

function LogoCloud() {
  return (
    <Box bg="gray.200">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3.125rem", md: "4.25rem" }}
      >
        {/* <Container centerContent> */}
        <Heading
          textAlign={"center"}
          // color="white"\
          color={Purple}
          // color="purple"
        >
          Your toolkit for Informed Decisions!
        </Heading>
        <br></br>
        <Text
          textAlign={"center"}
          // color={"white"}
          fontSize={"lg"}
          color={"black"}
        >
          Empowering Marketplaces sellers, Marketing agencies, Small businesses
          and Owners Alike.
        </Text>
        {/* </Container> */}
        <br></br>
        {/* <Spacer></Spacer> */}
        <AspectRatio ratio={1} maxH="350px">
          <iframe
            title="naruto"
            src="https://www.youtube.com/embed/NrXdauEv9HY"
            allowFullScreen
          />
        </AspectRatio>
        {/* </Center> */}
        {/* <Wrap
          spacing={{ base: "3rem", md: "3rem" }}
          justify={{ base: "center", xl: "space-between" }}
        >
          {partnerLogos.map((logo, index) => (
            <WrapItem key={index}>
              <Image
                src={logo}
                w="100%"
                h={["16px", "20px", "24px", "28px", "32px"]}
              />
            </WrapItem>
          ))}
        </Wrap> */}
      </Container>
    </Box>
  );
}

export default LogoCloud;
