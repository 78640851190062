import React from "react";

import {
  AspectRatio,
  Box,
  Circle,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Grey, Orange } from "utils/constants";

function OuterSection() {
  return (
    // <Stack bg={Grey}>
    <Stack mx={'7%'} h={'340px'}>
      <HStack>
        
        <VStack p={"4%"}>
          <Heading
            as="h4"
            size="lg"
            // color="white"
            color={"black"}
            pb={{ base: "1rem", md: "1.5rem" }}
            textAlign={{ base: "start", md: "center" }}
          >
           Drive more conversions on your Instagram Store with Aarith Smart Forms
          </Heading>

          <UnorderedList
            color={"black"}
            // color={"white"}
            fontSize={"xl"}
            fontFamily="revert"
          >
            <ListItem>Easy & seamless checkout experience</ListItem>
            <ListItem>Multiple payment modes</ListItem>
            <ListItem>Order reconciliations</ListItem>
            <ListItem>Exhibit credibility & convenience to buyer</ListItem>
          </UnorderedList>
        </VStack>
        <AspectRatio width={"40%"} m={"2%"} p={"2%"}>
          <Image
            // src="assets/images/hero/why_aarith.jpg"
            src="assets/images/hero/smart-form-outer.jpg"
            alt="naruto"
            objectFit="cover"
          />
        </AspectRatio>
      </HStack>
    </Stack>

    /* </Container>
    </Box> */
  );
}

export default OuterSection;
