import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import PricingBox from "components/PricingBox";
import { useLocation } from "react-router-dom";
import { Grey, Orange, Purple, modulesContent } from "utils/constants";

import {
  EmailSharp,
  WhatsApp,
 MediaBluetoothOn
} from "@mui/icons-material";
import { CheckCircle } from "phosphor-react";


const Engagement = () => {
  const location = useLocation();


  // const [productIndex, setProductIndex] = useState(0);
  const [productDetails, setProductDetails] = useState({});

  const getIconView = (boxHeader) => {
    if ("Email Engagement" === boxHeader) {
      return (
        <EmailSharp
          style={{
            width: "20%",
            height: "20%",
            color: `${Purple}`,
          }}
          fontSize="large"
        />
      );
    }
    if ("Social Media Engagement" === boxHeader) {
     return ( <MediaBluetoothOn
      style={{
        width: "20%",
        height: "20%",
        color: `${Purple}`,
      }}
      fontSize="large"
    />)
  
    }
    return (
      <WhatsApp
      style={{
        width: "20%",
        height: "20%",
        color: `${Purple}`,
      }}
      fontSize="large"
    />
    );
  };

  useEffect(() => {
    // const queryParam = new URLSearchParams(location);
    // // setProductIndex(queryParam.get("pathname").substr(-1));
    // const idVal = queryParam.get("pathname").substr(-1);
    // // console.log(idVal);
    // // console.log(id);
    const productObj = modulesContent[2];
    // console.log(productObj);
    setProductDetails(productObj);
  }, []);

  return (
    <>
    
    <div style={{backgroundColor:`${Purple}`}}>
      <Stack
        spacing={5}
        marginY={5}
        paddingX={[5, 0]}
      >
        <VStack alignItems="center" mt={'8px'} w="full">
          <Heading py={'10px'} color={'white'}> {`संचार`} - {productDetails?.header}</Heading>

          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              paddingBottom: "10px",
            }}
          >
            <Text
              fontWeight={"semibold"}
              fontSize={"lg"}
              color={"white"}
            >
              {productDetails?.subHeader}
            </Text>
          </div>
        </VStack>
      </Stack>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {productDetails?.boxValues?.map((item, index) => (
          <Stack
            spacing={2}
            border="3px solid"
            m={"2%"}
            width={"20%"}
            borderColor={Orange}
            borderRadius="0.7rem"
            p={4}
            h={"350px"}
            backgroundColor="white"
            position="relative"
          >
            {getIconView(item?.name)}

            <Text fontSize={"xl"}>{item?.name}</Text>
            <Divider borderColor="blackAlpha.500" />
            {/* {console.log(productDetails)} */}
            {item?.features?.map((item, index) => (
              <List flex="1">
                <ListItem fontSize={"lg"} key={Math.random()}>
                  <ListIcon as={CheckCircle} color="gray.400" />
                  {item}
                </ListItem>
              </List>
            ))}
          </Stack>
        ))}
      </div>
      {/* <Grid
        w="full"
        gap={5}
        justifyContent="center"
        templateColumns={{
          base: "inherit",
          md: "repeat( auto-fit, 250px )",
        }}
      >
        {["0", "2"].includes(productDetails?.id)
          ? productDetails?.boxValues?.map((price) => (
              <PricingBox
                showPrice={false}
                showSubHeader={false}
                key={price.name}
                // subHeader={price.subHeader}
                features={price.features}
                // info={price.info}
                name={price.name}
                popular={price.popular}
                // price={price.price}
                showButton={false}
              />
            ))
          : productDetails?.readItems?.map((item, index) => (
              <div>
                <Heading
                  as={"h4"}
                  size="md"
                  style={{
                    color: `${Purple}`,
                    marginLeft: "1%",
                    marginTop: "10px",
                  }}
                >
                  {item?.name}
                </Heading>
                <HStack>
                  <Box padding={"4px"} m="4px">
                    {item?.description}
                  </Box>
                </HStack>
              </div>
            ))}
      </Grid> */}

      {/* <Divider mt={"2%"} borderColor="gray.400" /> */}
    {/* </div> */}
      <Container
        centerContent
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "1.5rem", md: "2.5rem" }}
      >
        <Heading
          as="h2"
          size="h2"
          // color="white"
          color={"black"}
          pb={{ base: "1rem", md: "1rem" }}
          textAlign={{ base: "start", md: "center" }}
        >
          {productDetails?.subFooterHeader}
        </Heading>
        <UnorderedList
          color={"black"}
          // color={"white"}
          fontSize={"xl"}
          fontFamily="revert"
        >
          {/* <div
            style={{
              width: "500px",
              height: "500px",
              "line-height": "500px",
              "border-radius": "50%",
              "font-size": "50px",
              color: "#fff",
              "text-align": "center",
              background: `${Orange}`,
            }}
            class="circle"
          >
            Easy to integrate
          </div> */}
          {productDetails?.subFooterListItems?.map((item, index) => (
            <ListItem>{item}</ListItem>
          ))}
          {/* <ListItem>Easy to integrate</ListItem>
          <ListItem>Cost-efficient</ListItem>
          <ListItem>Mobile and Web Interface</ListItem> */}
        </UnorderedList>
        {/* <VStack spacing={{ base: "3rem", md: "6.5rem" }}>
          <Feature
            heading="Monetize your way"
            text="Set up subscriptions, rentals, or one-time buys for access to your VOD and live streams. Create exclusive experiences for your subscribers with coupons and promotions. One predictable fee, transparent pricing, and same-day setup."
            imageSrc="assets/images/features/feature-image-1.png"
            imageAlt="Feature Image 1"
            to="/"
          >
            <Parallax position="absolute" top="-1rem" left="-1rem">
              <DonateOverlay />
            </Parallax>
          </Feature>
          <Feature
            heading="Optimized for growth"
            text="The Aarith ecosystem is designed to help you generate profit. Set up complete sales and marketing funnels with ease using the Aarith built-in marketing tools and integrations. Generate leads and convert your audience into paying subscribers, at no extra cost."
            imageSrc="assets/images/features/feature-image-2.png"
            imageAlt="Feature Image 2"
            reverse={true}
            to="/"
          >
            <HoverBadge
              borderRadius="0.75rem"
              p="1rem"
              fontSize="32px"
              position="absolute"
              top="-1rem"
              right="-1rem"
            >
              <EnvelopeSimple />
              <Circle
                size="0.75rem"
                bg="blue.400"
                position="absolute"
                top="1rem"
                right="0.85rem"
                border="2px"
                borderColor="white"
              />
            </HoverBadge>
          </Feature>
        </VStack> */}
      </Container>
    </>
  );
};

export default Engagement;
